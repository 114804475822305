import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { Helmet } from "react-helmet"
import TagManager from "react-gtm-module"

// hooks
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"

import { useDocumentTitle } from "../../hook"

// Costum Components
import {
  HeroSection,
  ValueToClientCard,
  FindExpertCard,
  SocialsCard,
} from "../../components/cards"
import UnalignedItemsContainer from "../../components/layout/UnalignedItemsContainer"
import ApproachSection from "../../components/section/ApproachSection"
import {
  ContainerContent,
  IndustryContainer,
  StyledContainer,
} from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import ArticlesSection from "../../components/section/ArticlesSection"

import { ContactSubscribeCard } from "../../components/cards"

//Actions
import {
  fetchExpertise,
  fetchHlsExpertises,
} from "../../store/actions/hls/hlsHome"

const ServicePageContainer = styled.div`
  .container-left {
    padding: 50px 10px;
  }
  .leftSection {
    .industrySection {
      h3 {
        color: #00244a;
      }
      .expertiseIndustry {
        color: var(--hover-blue);
        padding-right: 1rem;
        .expertiseIndustryText {
          cursor: pointer;
        }
      }
    }
  }
  .heroContainer {
    h1 {
      font-size: 80px;
      line-height: 80px;
      letter-spacing: 1px;
    }
  }
`
const ApproachContainer = styled.div`
  h3 {
    color: var(--darkBlueHome);
  }
`

const ArticlesContainer = styled.div`
  width: 100%;
  background-color: var(--graySections);
  color: var(--darkBlueHome);
`

const SingleServicePage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { state } = useLocation()

  const expertise = useSelector(state => state?.hlsHero?.expertise)
  const expertises = useSelector(state => state?.hlsHero?.expertises)

  useEffect(() => {
    window.scrollTo(0,0)
    dispatch(fetchExpertise(state?.id))
  }, [state, dispatch])

  // access directly from link
  useEffect(() => {
    if (!expertise.name) {
      dispatch(fetchHlsExpertises())

      const URLLocation = location?.pathname?.split("/")[2]
      const expertiseName = URLLocation.split("-").join(" ").toUpperCase()
      let x = expertises?.filter(x => x?.name?.toUpperCase() === expertiseName)

      dispatch(fetchExpertise(x[0]?.id))
    }
  }, [dispatch, expertise?.name, expertises, location])

  useDocumentTitle(
    `Services |   ${
      expertise?.name ? expertise?.name : " "
    }    | Alvarez & Marsal`
  )

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': `Services |   ${ expertise?.name ? expertise?.name : " " }`,
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <ServicePageContainer>
      <Helmet>
        <link rel="canonical" href={state?.canonicalUrl} />
      </Helmet>

      <HeroSection
        height={360}
        pageCategory="Services"
        title={expertise?.name}
        icon={expertise?.icon}
        backgroundUrl={expertise?.backgroundUrl}
      />
      <ContainerContent>
        <IndustryContainer>
          <div className="d-lg-flex">
            <div className="col-12 col-lg-8 container-left">
              <ApproachContainer>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: expertise?.description ?? expertise?.teaser_text,
                  }}
                />
                {(expertise?.hls_points || expertise?.hls_approachs) && (
                  <ApproachSection
                    points={expertise?.hls_points}
                    approachs={expertise?.hls_approachs}
                  />
                )}
              </ApproachContainer>
              <UnalignedItemsContainer
                className="px-0"
                columnsNumber={expertise?.articles?.legnth}
              ></UnalignedItemsContainer>
            </div>

            <div className="py-5 expertsContainer col-12 col-lg-4 ps-lg-3 ps-xxl-5 container-right">
              <div className="w-100">
                {expertise?.value_to_client && (
                  <ValueToClientCard values={expertise?.value_to_client} />
                )}
                <FindExpertCard />
                <ContactSubscribeCard />
                <SocialsCard />
              </div>
            </div>
          </div>
        </IndustryContainer>
      </ContainerContent>
      {expertise?.articles && (
        <ArticlesContainer>
          <StyledContainer>
            <ArticlesSection articles={expertise?.articles} />
          </StyledContainer>
        </ArticlesContainer>
      )}
    </ServicePageContainer>
  )
}

export default SingleServicePage
