import React from "react"
import * as S from "./styles/ButtonWithAnimation.style"
import { ChevronRight } from "../../assets/icons"

const ButtonWithAnimation = ({ text, color, href = null, onClick, target = null}) => {
  return (
    <S.Container onClick={onClick} href={href} color={color} target={target}>
      <S.ChevronContainer color={color}>
        <ChevronRight />
      </S.ChevronContainer>
      <S.TextContainer color={color}>
        <div>{text || "Read More"}</div>
      </S.TextContainer>
    </S.Container>
  )
}

export default ButtonWithAnimation
