import React from "react"
import * as S from "./styles/ArticlePreviewCard.styles"
import { useNavigate } from "react-router"
import { routeNames } from "../../routes/routes"
import DOMPurify from "dompurify"

const ArticlePreviewCard = ({
  alias,
  uuid,
  title,
  teaserText,
  teaserImage,
  date,
  insightType,
  canonicalUrl,
  nid,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${routeNames.insights}/${alias}`, {
      state: { uuid, canonicalUrl, nid },
    })
  }

  return (
    <S.Card>
      <S.Category
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(insightType),
      }}></S.Category>
      <S.Title
        onClick={handleClick}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(title),
        }}
      ></S.Title>
      <S.Date>{date}</S.Date>
      <S.Content
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(teaserText),
        }}
      ></S.Content>
      <S.View>
        {teaserImage && <img src={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/${teaserImage}`} alt="article" />}
      </S.View>
    </S.Card>
  )
}

export default ArticlePreviewCard
