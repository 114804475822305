import React, { useMemo } from "react"
import styled from "styled-components"
import { ChevronRight } from "../../assets/icons"

const Title = styled.h2`
  color: #00244a;
  text-transform: uppercase;
`

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const Tab = styled.h5`
  color: #0085ca;
  text-transform: uppercase;
  font-weight: ${props => (props.active ? "bold" : "normal")};
  cursor: pointer;
  margin-right: 20px;
  &:not(:first-child)::before {
    content: "|";
    font-weight: normal;
    margin-right: 20px;
  }
`

const ItemListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  svg {
    width: 15px;
    height: 15px;
  }
`

const Item = styled.h5`
  cursor: pointer;
  font-weight: bold;
  color: ${props => (props.active ? "#0085CA" : "#002549")};
  padding-bottom: 10px;
  margin-bottom: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #b5b5b5;
  }
`

const MediaList = ({
  categories,
  activeCategory,
  activeItem,
  handleCategoryClick,
  handleItemClick,
  handleClick 
}) => {
  const activeCategoryObject = useMemo(
    () => categories.find(category => category.name === activeCategory),
    [categories, activeCategory]
  )

  return (
    <>
      {categories && <Title>Thought Leadership</Title>}
      {/* <TabContainer>
        {categories.map(category => (
          <Tab
            key={category.name}
            active={category.name === activeCategory}
            onClick={() => handleCategoryClick(category.name)}
          >
            {category.name}
          </Tab>
        ))}
      </TabContainer> */}
      {activeCategoryObject && (
        <ItemListContainer>
          {activeCategoryObject.items.slice(0, 6).map(item => (
            <Item
              key={item.id}
              active={item.id === activeItem}
              onClick={() => {handleItemClick(item); handleClick();}}
            >
              {item.title}
              &nbsp;
              <ChevronRight />
            </Item>
          ))}
        </ItemListContainer>
      )}
    </>
  )
}

export default MediaList
