import Axios from "axios"
import {
  dateFromSecondsToShortLocale,
  dateToShortLocale,
} from "../../../utils/dateFormat"

export const GET_HERO_SECTION_DATA = "GET_HERO_SECTION_DATA"
export const GET_SLIDES = "GET_SLIDES"
export const GET_INDUSTRIES = "GET_INDUSTRIES"
export const GET_COUNTER_LIST = "GET_COUNTER_LIST"
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_EXPERTISES = "GET_EXPERTISES"

export const GET_INDUSTRY = "GET_INDUSTRY"
export const GET_EXPERTISE = "GET_EXPERTISE"
export const GET_INSIGHTS_ARTICLES = "GET_INSIGHTS_ARTICLES"
export const GET_RECENT_RECOGNITION = "GET_RECENT_RECOGNITION"
export const GET_INDUSTRY_ARTICLES = "GET_INDUSTRY_ARTICLES"
const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const link = process.env.REACT_APP_CUSTOM_API_URL + "/hls"

export const fetchData = () => {
  return async dispatch => {
    Axios.get(link)
      .then(data => {
        const bannerTitle = data?.data.main_homepage_slider_title
        const bannerTeaser = data?.data.main_homepage_slider_teaser
        const bannerImage = data?.data.main_homepage_slider_img
        const slides = data?.data.front_slide
        let slidesArr = []
        for (const [, value] of Object.entries(slides)) {
          let slide = {
            bannerTitle: bannerTitle,
            bannerTeaser: bannerTeaser,
            bannerImage: `${BASE_LINK}${bannerImage}`,
            title: value.title,
            eyebrow: value.eyebrow,
            link: value.link,
            backgroundUrl: `${value.background_image}`,
          }
          slidesArr.push(slide)
        }
        dispatch({
          type: GET_SLIDES,
          payload: slidesArr,
        })

        const expertises = data?.data.expertise_parent_children
        let expertisesArr = []
        for (const [, value] of Object.entries(expertises)) {
          let expertise = {
            id: value.id,
            name: value.name,
            icon: value.svg_icon,
            is_front: value.is_front,
            description: value.teaser_text,
            backgroundUrl: `${BASE_LINK}${value.background_image}`,
            canonicalUrl: value.url["#attributes"]["href"],
          }
          expertisesArr.push(expertise)
        }
        dispatch({
          type: GET_EXPERTISES,
          payload: expertisesArr,
        })

        const front_counter_title = data?.data.front_counter_label_title
        const front_counter_list = data?.data.front_counter_label_list
        let front_counterArr = []
        for (const [, value] of Object.entries(front_counter_list)) {
          let front_counter = {
            label: value.label,
            counter: value.counter,
          }
          front_counterArr.push(front_counter)
        }
        dispatch({
          type: GET_COUNTER_LIST,
          payload: {
            title: front_counter_title,
            list: front_counterArr,
          },
        })

        const block_medias = data?.data.block_insight_podcast_video
        const categories = {
          insights: block_medias.article.map(item => {
            const alias = item.url["#attributes"]["href"].substring(
              item.url["#attributes"]["href"].lastIndexOf("/") + 1
            )
            return {
              id: item.id,
              uuid: item.uuid,
              alias: alias,
              canonicalUrl: item.url["#attributes"]["href"],
              title: item.title,
              date: item.created,
              textTeaser: item.text_teaser,
              backgroundUrl: `${BASE_LINK}${item.field_featured_background_image}`,
            }
          }),
          // podcasts: block_medias.podcast.map(item => {
          //   const alias = item.url.substring(item.url.lastIndexOf("/") + 1)
          //   return {
          //     id: item.id,
          //     uuid: item.uuid,
          //     alias: alias,
          //     title: item.title,
          //     date: item.created,
          //     textTeaser: item.text_teaser,
          //     backgroundUrl: `${BASE_LINK}${item.field_featured_background_image}`,
          //   }
          // }),
          // videos: block_medias.video.map(item => {
          //   const alias = item.url.substring(item.url.lastIndexOf("/") + 1)
          //   return {
          //     id: item.id,
          //     uuid: item.uuid,
          //     alias: alias,
          //     title: item.title,
          //     date: item.created,
          //     textTeaser: item.text_teaser,
          //     videoUrl: item.field_video,
          //     backgroundUrl: `${BASE_LINK}${item.field_featured_background_image}`,
          //   }
          // }),
        }
        const result = Object.keys(categories).map(key => ({
          name: key,
          items: categories[key],
        }))
        dispatch({
          type: GET_CATEGORIES,
          payload: result,
        })

        const industry = data?.data.expertise_parent
        const obj = {
          id: industry.id,
          title: industry.name,
          description: industry.teaser_text,
          backgroundUrl: `${BASE_LINK}${industry.background_image}`,
        }
        dispatch({
          type: GET_HERO_SECTION_DATA,
          payload: obj,
        })

        const articles = data.data.block_two
        articles?.data.forEach(article => {
          article.categoryName = data.data.block_two.title
          article.date = dateFromSecondsToShortLocale(article.updated)
        })
        dispatch({
          type: GET_INSIGHTS_ARTICLES,
          payload: articles,
        })

        dispatch({
          type: GET_RECENT_RECOGNITION,
          payload: data?.data.quotes,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchHLSHeroSection = industryId => {
  return async dispatch => {
    Axios.get(link)
      .then(data => {
        const industry = data?.data.expertise_parent
        const obj = {
          id: industry.id,
          title: industry.name,
          description: industry.teaser_text,
          backgroundUrl: `${BASE_LINK}${industry.background_image}`,
        }
        dispatch({
          type: GET_HERO_SECTION_DATA,
          payload: obj,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchHLSIndustries = () => {
  return async dispatch => {
    await Axios.get(link)
      .then(data => {
        let parsedIndustriesArray = []

        const industries = data?.data.healthcare_industries
        for (const [, value] of Object.entries(industries)) {
          let industryObj = {
            id: value.id,
            name: value.name,
            description: value.teaser_text,
            uuid: value.uuid,
          }
          parsedIndustriesArray.push(industryObj)
        }

        dispatch({
          type: GET_INDUSTRIES,
          payload: parsedIndustriesArray,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export const fetchIndustry = industryId => {
  return async dispatch => {
    let industry = {}
    let experts = []
    let expertises = []

    Axios.get(link)
      .then(data => {
        const industries = data.data.healthcare_industries
        for (const [key, value] of Object.entries(industries)) {
          if (key === industryId) {
            value.backgroundUrl = `${BASE_LINK}${value.background_image}`
            industry = value
          }
        }

        industry?.featured_expert?.forEach(expert => {
          for (const [key, value] of Object.entries(
            data?.data?.expertise_parent_expert_profile
          )) {
            if (key === expert) {
              value.alias = value.url.split("/")[2]

              experts.push(value)
            }
          }
        })

        industry?.expertise?.forEach(expert => {
          for (const [key, value] of Object.entries(
            data?.data?.expertise_parent_children
          )) {
            if (key === expert) {
              expertises.push(value)
            }
          }
        })

        industry.expertises = expertises
        industry.experts = experts

        dispatch({
          type: GET_INDUSTRY,
          payload: industry,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchIndustryArticles = industryUUID => {
  return async dispatch => {
    const link = `${BASE_LINK}/jsonapi/node/article?include=field_category&filter[field_industry.id]=${industryUUID}`
    Axios.get(link)
      .then(data => {
        const jsonData = data?.data?.data
        const dataIncluded = data?.data.included

        let articles = []
        jsonData?.forEach(article => {
          let x = {
            uuid: article.id,
            title: article.attributes.title,
            alias: article.attributes.path?.alias.split("/")[2],
            text_teaser: article.attributes.field_teaser_text,
            date: dateToShortLocale(
              article.attributes.changed || article.attributes.created
            ),
            category: {
              id: article.relationships.field_category.data[0].id,
              type: article.relationships.field_category.data[0].type,
            },
          }
          articles.push(x)
        })

        articles?.forEach(article => {
          dataIncluded.forEach(included => {
            if (article.category.type === included.type) {
              article.categoryName = included.attributes.name
            }
          })
        })

        dispatch({
          type: GET_INDUSTRY_ARTICLES,
          payload: articles,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchHlsExpertises = () => {
  return async dispatch => {
    Axios.get(link)
      .then(data => {
        const expertises = data?.data.expertise_parent_children
        let expertisesArr = []
        for (const [, value] of Object.entries(expertises)) {
          let expertise = {
            id: value.id,
            name: value.name,
            icon: value.svg_icon,
            description: value.teaser_text,
            canonicalUrl: value.url["#attributes"]["href"],
          }
          expertisesArr.push(expertise)
        }
        dispatch({
          type: GET_EXPERTISES,
          payload: expertisesArr,
        })
      })
      .catch(err => {
        // console.log(err)
      })
  }
}

export const fetchExpertise = expertiseId => {
  return async dispatch => {
    Axios.get(link)
      .then(data => {
        const expertises = data?.data.expertise_parent_children
        let experts = []
        let expertise = {}
        let expertiseIndustries = []

        for (const [key, value] of Object.entries(expertises)) {
          if (key === expertiseId) {
            value.backgroundUrl = `${BASE_LINK}${value.background_image}`
            value.icon = value.svg_icon
            expertise = value
            value.hls_approachs?.map(
              (item, index) => (item.image = `${BASE_LINK}${item.image}`)
            )
          }
        }
        expertise?.featured_expert?.forEach(expert => {
          for (const [key, value] of Object.entries(
            data?.data.expertise_parent_expert_profile
          )) {
            if (key === expert) {
              value.alias = value.url["#attributes"]["href"].split("/")[2]
              experts.push(value)
            }
          }
        })

        let articles = expertise?.articles?.forEach(expert => {
          for (const [key, value] of Object.entries(
            data?.data.expertise_parent_articles
          )) {
            if (key === expert) {
              return value
            }
          }
        })

        const industries = data.data.healthcare_industries
        for (const [, value] of Object.entries(industries)) {
          value?.expertise?.forEach(expertiseID => {
            if (expertiseId === expertiseID) {
              expertiseIndustries.push(value)
            }
          })
        }

        let formatedArticles = []
        articles?.forEach(article => {
          article.date = dateFromSecondsToShortLocale(article?.updated)
          formatedArticles.push(article)
        })

        expertise.articles = articles
        expertise.experts = experts
        expertise.industries = expertiseIndustries

        dispatch({
          type: GET_EXPERTISE,
          payload: expertise,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchInsightsArticles = () => {
  return async dispatch => {
    await Axios.get(link)
      .then(data => {
        const articles = data.data.block_two
        articles?.data.forEach(article => {
          article.categoryName = data.data.block_two.title
          article.date = dateFromSecondsToShortLocale(article.updated)
        })

        dispatch({
          type: GET_INSIGHTS_ARTICLES,
          payload: articles,
        })
      })

      .catch(err => {
        console.log(err)
      })
  }
}

export const fetchRecentRecognition = () => {
  return async dispatch => {
    await Axios.get(link)
      .then(data => {
        dispatch({
          type: GET_RECENT_RECOGNITION,
          payload: data?.data.quotes,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}
