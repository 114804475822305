import styled from "styled-components/macro"
import { sizem } from "../../../../utils/breakpoints"
export const FiltersContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 1rem;
  /* margin-top: ${props => props.marginTop || "9rem"}; */
  margin-bottom: 1rem;
  @media ${sizem.mdm_m} {
    display: block;
  }
  @media ${sizem.smm} {
    display: block;
  }
`
