import Axios from "axios"
export const GET_ALL_LEADERS = "GET_ALL_LEADERS"
export const GET_FILTER_TAGS = "GET_FILTER_TAGS"
export const FILTER_LEADERS = "FILTER_LEADERS"
export const GET_ALL_PERSONS = "GET_ALL_PERSONS"
export const GET_FILTERED_PERSONS = "GET_FILTERED_PERSONS"
export const ADD_NAVBAR_FILTERS = "ADD_NAVBAR_FILTERS"
export const FILTER_NAVBAR_FILTERS = "FILTER_NAVBAR_FILTERS"
export const LOADING_PERSONS = "LOADING_PERSONS"
export const LOADING_LEADERS = "LOADING_LEADERS"
export const GET_MORE_PERSONS = "GET_MORE_PERSONS"

const BASE_LINK = process.env.REACT_APP_BASE_API_URL;

export const fetchHLSPersons = (offset, limit) => {

  return async dispatch => {
    const link = `${BASE_LINK}/jsonapi/node/profile?include=field_professional_title,field_image_background,field_image,field_expertise,field_city,field_region,field_industry&filter[field_industry.id]=c11b8f8f-9d3a-433a-949e-5518b9b24c25&filter[not_searchable_group][group][conjunction]=OR&filter[false_filter][condition][path]=field_not_searchable&filter[false_filter][condition][operator]=%3D&filter[false_filter][condition][value]=false&filter[false_filter][condition][memberOf]=not_searchable_group&filter[null_filter][condition][path]=field_not_searchable&filter[null_filter][condition][operator]=IS NULL&filter[null_filter][condition][memberOf]=not_searchable_group&page[offset]=${offset}&page[limit]=${limit}`;

    await Axios.get(link)
      .then(data => {
        const dataIncluded = data?.data.included
        const profiles = getInformationOfLeaders(dataIncluded, data?.data.data)
        let obj = {}
        let expertises = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--expertise"
        )
        let locations = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--global_locations"
        )
        data.data.included?.filter(
          x => x.type === "taxonomy_term--global_locations"
        )
        let cities = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--cities"
        )
        let industries = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--industries"
        )
        obj = {
          profiles: profiles,
          cities: cities,
          expertises: expertises,
          location: locations,
          industries: industries,
          filters: [
            { values: locations, name: "country" },
            { values: cities, name: "city" },
          ],
        }

        dispatch({
          type: GET_ALL_PERSONS,
          payload: obj,
        })
      })
      .catch(err => {
        // console.log(err)
      })
  }
}

export const fetchHLSLeaders = () => {
  return async dispatch => {
    dispatch({ type: LOADING_LEADERS, payload: true })

    const link = `${BASE_LINK}/jsonapi/taxonomy_term/industries/ca12f9a8-bfed-4fc1-9a04-5cc560a91fff?include=field_featured_expert.field_professional_title,
    field_featured_expert.field_image_background,
    field_featured_expert.field_image,
    field_featured_expert.field_expertise,
    field_featured_expert.field_city,
    field_featured_expert.field_region`

    await Axios.get(link)
      .then(data => {
        let obj = {}

        const dataIncluded = data?.data.included

        let profiles = data.data.included?.filter(
          x => x.type === "node--profile"
        )
        let expertises = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--expertise"
        )
        let global_locations = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--global_locations"
        )
        let cities = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--cities"
        )

        const leaders = getInformationOfLeaders(dataIncluded, profiles)
        obj = {
          leaders: leaders,
          cities: cities,
          expertises: expertises,
          location: global_locations,
        }

        dispatch({ type: LOADING_LEADERS, payload: false })
        dispatch({
          type: GET_ALL_LEADERS,
          payload: obj,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: LOADING_LEADERS, payload: false })
      })
  }
}

//get information: field_image_background , field_image, field_region
// require includedArray  , and an array of profiles
const getInformationOfLeaders = (dataIncluded, profiles) => {
  const linkLocation = `${BASE_LINK}/global-locations/`
  const liveAM = BASE_LINK
  const linkOurPeople = BASE_LINK

  let parsedProfiles = []

  profiles?.forEach(profile => {
    let imageId = profile?.relationships?.field_image_background?.data?.id
    let imageId1 = profile?.relationships?.field_image?.data?.id

    let professionId =
      profile?.relationships?.field_professional_title?.data[0]?.id
    let locationId = profile?.relationships?.field_region?.data[0]?.id
    let firstName = profile.attributes?.field_first_name
    let lastName = profile.attributes?.field_last_name
    let country = dataIncluded?.find(x => x.id === locationId)

    let imageURL =
      dataIncluded?.find(x => x.id === imageId)?.attributes.uri.url ||
      dataIncluded?.find(x => x.id === imageId1)?.attributes.uri.url

    let person = {
      id: profile.id,
      firstName: firstName,
      lastName: lastName,
      name:
        profile.attributes?.field_first_name +
        " " +
        profile.attributes?.field_last_name,

      phone: profile.attributes?.field_telephone,
      smallDescription: profile.attributes?.field_intro_text,
      allDescription: profile.attributes.body?.value,
      email: {
        uri: profile.attributes.field_mail?.uri,
        title: profile.attributes.field_mail?.title,
      },
      twitter: {
        uri: profile.attributes.field_twitter?.uri,
        title: profile.attributes.field_twitter?.title,
      },
      linkedin: {
        uri: profile.attributes.field_linkedin?.uri,
        title: profile.attributes.field_linkedin?.title,
      },
      position: dataIncluded?.find(x => x.id === professionId)?.attributes
        ?.name,

      country: {
        id: country?.id,
        name: country?.attributes?.name,
        type: country?.type,
      },
      image: imageURL && liveAM + imageURL,

      linkLocation:
        linkLocation +
        country?.attributes?.name.split(" ").join("-").toLowerCase(),
      linkOurPeople:
        linkOurPeople + profile?.attributes?.path?.alias,
      city: profile?.relationships?.field_city?.data[0],
      expertise: profile?.relationships?.field_expertise?.data[0],
    }
    parsedProfiles = [...parsedProfiles, person]
  })
  return parsedProfiles
}

const getIdAndNamebyTaxonomyType = (dataIncluded, type) => {
  const newArr = dataIncluded
    ?.filter(x => x.type === type)
    ?.map(city => {
      let newCity = {
        id: city.id,
        name: city.attributes.name,
        type: type,
      }
      return newCity
    })
  return newArr
}

export const filterHLSPersons = (offset, limit, loadMore) => {
  return async (dispatch, getState) => {
    !loadMore && dispatch({ type: LOADING_PERSONS, payload: true })

    const filters = getState().leaders.navbarFilters
    //if no filters fetch all persons for HEALTCARE

    let filterByIndustry = ""
    let filterByExpertise = ""
    let filterByCountry = ""
    let filterByCity = ""

    //prepare filters from filter navbar ,to pass in url
    filters?.forEach(filter => {
      if (filter?.type === "taxonomy_term--industries") {
        filterByIndustry = `&filter[field_industry.id]=${filter.id}`
      } else if (filter?.type === "taxonomy_term--global_locations") {
        filterByCountry = `&filter[field_region.id]=${filter.id}`
      } else if (filter?.type === "taxonomy_term--cities") {
        filterByCity = `&filter[field_city.id]=${filter.id}`
      } else if (filter?.type === "taxonomy_term--expertise") {
        filterByExpertise = `&filter[field_expertise.id]=${filter.id}`
      }
      filterByIndustry = `&filter[field_industry.id]=${filter.id}`
    })

    const link = `${BASE_LINK}/jsonapi/node/profile?include=field_professional_title,field_image_background,field_image,field_expertise,field_city,field_region,field_industry&filter[field_industry.id]=c11b8f8f-9d3a-433a-949e-5518b9b24c25&filter[not_searchable_group][group][conjunction]=OR&filter[false_filter][condition][path]=field_not_searchable&filter[false_filter][condition][operator]=%3D&filter[false_filter][condition][value]=false&filter[false_filter][condition][memberOf]=not_searchable_group&filter[null_filter][condition][path]=field_not_searchable&filter[null_filter][condition][operator]=IS NULL&filter[null_filter][condition][memberOf]=not_searchable_group${filterByExpertise}${filterByCountry}${filterByCity}&page[offset]=${offset}&page[limit]=${limit}`
    await Axios.get(link)
      .then(data => {
        const dataIncluded = data?.data?.included
        const leadersIncluded = getState().leaders.leaders.map(leader => leader.id)
        const profiles = getInformationOfLeaders(dataIncluded, data?.data?.data)
        let obj = {}
        let expertises = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--expertise"
        )
        let locations = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--global_locations"
        )
        data.data.included?.filter(
          x => x.type === "taxonomy_term--global_locations"
        )
        let cities = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--cities"
        )
        let industries = getIdAndNamebyTaxonomyType(
          dataIncluded,
          "taxonomy_term--industries"
        )

        // exclude leaders from profiles
        const filteredProfiles = profiles.filter(profile => !leadersIncluded.includes(profile.id))

        obj = {
          profiles: filteredProfiles,
          cities: cities,
          expertises: expertises,
          location: locations,
          industries: industries,
          filters: [
            { values: locations, name: "country" },
            { values: cities, name: "city" },
          ],
          loadMore: loadMore,
        }

        !loadMore && dispatch({ type: LOADING_PERSONS, payload: false })

        dispatch({
          type: GET_FILTERED_PERSONS,
          payload: obj,
        })
      })
      .catch(err => {
        console.log(err)

        !loadMore && dispatch({ type: LOADING_PERSONS, payload: false })
      })
  }
}

export const filterHLSPersons1 = pageNumber => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_PERSONS, payload: true })
    const filters = getState().leaders.navbarFilters
    if (filters.length === 0) {
      await fetchHLSPersons()
    } else {
      let filterByIndustry = ""
      let filterByExpertise = ""
      let filterByCountry = ""
      let filterByCity = ""

      //prepare filters from filter navbar ,to pass in url
      filters?.forEach(filter => {
        if (filter?.type === "taxonomy_term--industries") {
          filterByIndustry = `&filter[field_industry.id]=${filter.id}`
        } else if (filter?.type === "taxonomy_term--global_locations") {
          filterByCountry = `&filter[field_region.id]=${filter.id}`
        } else if (filter?.type === "taxonomy_term--cities") {
          filterByCity = `&filter[field_city.id]=${filter.id}`
        } else if (filter?.type === "taxonomy_term--expertise") {
          filterByExpertise = `&filter[field_expertise.id]=${filter.id}`
        }
      })

      const link = `${BASE_LINK}/jsonapi/node/profile?include=field_professional_title,field_image_background,field_image,field_expertise,field_city,field_region,field_industry${filterByIndustry}${filterByExpertise}${filterByCountry}${filterByCity}&page[offset]=${pageNumber}&page[limit]=50`

      await Axios.get(link)
        .then(data => {
          const dataIncluded = data?.data?.included
          const profiles = getInformationOfLeaders(
            dataIncluded,
            data?.data?.data
          )

          dispatch({ type: LOADING_PERSONS, payload: false })
          dispatch({
            type: GET_MORE_PERSONS,
            payload: profiles,
          })
        })
        .catch(err => {
          console.log(err)
          dispatch({ type: LOADING_PERSONS, payload: false })
        })
    }
  }
}

export const addNavbarFilters = filter => {
  return dispatch => {
    dispatch({
      type: ADD_NAVBAR_FILTERS,
      payload: filter,
    })
  }
}

export const filterNavbar = filter => {
  return dispatch => {
    dispatch({
      type: FILTER_NAVBAR_FILTERS,
      payload: filter,
    })
  }
}
export const addFilterTags = tag => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_FILTER_TAGS,
      payload: tag,
    })
  }
}