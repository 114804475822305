import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { size } from "../../utils/breakpoints"

const DropDownContainer = styled.div`
  max-width: 300px;
`

const DropDownHeader = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #0085ca;
  padding: 0 40px 0 10px;
  height: 40px;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  position: relative;
  :hover {
    color: #fff;
  }
  :after {
    position: absolute;
    content: "";
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background: transparent
      url(https://www.alvarezandmarsal.com/themes/custom/am/images/icons/arrow-right-white-64.png)
      no-repeat center center;
    background-size: contain;
    transform: ${({ isActive }) =>
      isActive ? "rotate(-90deg)" : "rotate(90deg)"};
    transition: all 0.5s;
  }
`

const DropDownListContainer = styled.div`
  top: 100%;
`

const DropDownList = styled.ul`
  overflow-y: auto;
  height: 310px;
  background-color: #0085ca;
  position: absolute;
  z-index: 99;
  width: 88%;
  max-width: 750px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${size.md} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    overflow: unset;
    height: unset;
  }
`

const ListItem = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
`
const Link = styled.a`
  display: block;
  padding: 1rem 2rem;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  color: white;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #00244a;
    color: #fff;
  }
`

const TestButton = ({ location }) => {
  const [isActive, setIsActive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [isSelected, setisSelected] = useState(null)
  const dropdownRef = useRef()

  const toggling = () => {
    setIsOpen(isOpen => !isOpen)
    setIsActive(current => !current)
  }

  const handleClick = location => {
    setSelectedOption(location.country)
    setIsOpen(false)
    setisSelected(location.country.country)
  }

  // Listtening to clicks outside of the dropdown
  useEffect(() => {
    const closeDropdown = e => {
      if (!dropdownRef.current.contains(e.target) && isOpen) {
        setIsOpen(false)
      }
    }

    document.body.addEventListener("click", closeDropdown)

    return () => {
      document.body.removeEventListener("click", closeDropdown)
    }
  }, [isOpen])

  return (
    <>
      <DropDownContainer ref={dropdownRef}>
        <DropDownHeader isActive={isActive} onClick={toggling}>
          {selectedOption || "Jump to Country"}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {location?.map((location, index) => (
                <ListItem
                  onClick={() => handleClick(location.country)}
                  key={index}
                >
                  <Link href={"#" + location.country.idd}>
                    {location.country.country}
                  </Link>
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </>
  )
}
export default TestButton
