import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ArticlePreviewCard } from "../../components/cards"
import { getSearchResults } from "../../API"
import { useSelector, useDispatch } from "react-redux"
import { Spinner } from "../../components"
import InfiniteScroll from "react-infinite-scroll-component"
import { ServicesBannerImage } from "../../assets/images/index"
import { HeroSection } from "../../components/cards"
import { addFilter } from "../../store/actions/filters"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router"
import { Container, Row } from "react-bootstrap"
import styled from "styled-components/macro"
import { Search } from "../../assets/icons"
import TagManager from "react-gtm-module"

const Input = styled.input`
  font-size: 20px;
  border-width: 0 0 1px 0;
  height: 40px;
  width: 400px;
  outline: none;
  border-bottom-style: solid;
  background-color: transparent;
  color: var(--darkBlue2);
  font-weight: 100;
  border-bottom-color: var(--darkBlue2);
`

const Button = styled.button`
  background-color: #fff;
  color: var(--darkBlue2);
  border: 0;
`

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const SearchPage = ({ insightType }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchItem, setSearchItem] = useState("")
  const { currentInsightType, filters } = useSelector(state => state.filters)
  const [hasMore, setHasMore] = useState(true)
  const [articles, setArticles] = useState(null)
  const [nextPage, setNextPage] = useState(0)

  if (insightType === undefined) {
    insightType = currentInsightType
  }

  useEffect(() => {
    if (filters.length !== 0) {
      const getData = async () => {
        const data = await getSearchResults(insightType, filters, nextPage)
        setArticles(data)

        if (data.length === 0) {
          setHasMore(false)
        } else {
          setArticles(data)
          setHasMore(true)
        }
      }
      getData()
    }
  }, [filters])

  useEffect(() => {
    setSearchItem(id)
  }, [id])

  useEffect(() => {
    setNextPage(0)
  }, [filters])

  const getMoreArticles = async () => {
    setNextPage(nextPage + 1)
    const data = await getSearchResults(insightType, filters, nextPage + 1)
    setArticles([...articles, ...data])

    if (data.length === 0) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }

  const inputChanges = e => {
    setSearchItem(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const searchValue = e.target[0].value
    if (searchValue.trim() !== "") {
      navigate(`/search/${searchValue}`)
    }

    dispatch(
      addFilter({
        name: searchItem,
        category: "userInput",
      })
    )
  }

  if (!articles) {
    return <Spinner />
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Search',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}/search/site/${searchItem}`} />
      </Helmet>

      <HeroSection title="Search" backgroundUrl={ServicesBannerImage} />

      <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Row>
          <form onSubmit={e => handleSubmit(e)} style={{ textAlign: "center" }}>
            <Input
              placeholder="SEARCH"
              value={searchItem}
              onChange={e => inputChanges(e)}
            ></Input>
            <Button>
              <Search />
            </Button>
          </form>
        </Row>

        <InfiniteScroll
          dataLength={articles.length || 0}
          hasMore={hasMore}
          next={getMoreArticles}
          loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
        >
          {articles.map((item, index) => {
            return <ArticlePreviewCard key={index} {...item} />
          })}
        </InfiniteScroll>
      </Container>
    </>
  )
}

export default SearchPage
