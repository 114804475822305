import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { HeroSection } from "../../components/cards"
import SocialsCardSmall from "../../components/cards/SocialsCardSmall"
import {
  fetchIndustry,
  fetchIndustryArticles,
} from "../../store/actions/hls/hlsHome"
import { ProfessionalCard, ContactSubscribeCard } from "../../components/cards"
import styled from "styled-components/macro"
import TagManager from "react-gtm-module"
import {
  ContainerContent,
  FullWidthContainer,
  StyledContainer,
} from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import ExpertiseSection from "../../components/section/ExpertiseSection"
import ArticlesSection from "../../components/section/ArticlesSection"
import { IndustryContainer } from "../../components/layout/Rows&Collumns/Rows&Collumns.style.jsx"
import { Spinner } from "../../components"
import { useDocumentTitle } from "../../hook"
import { fetchHLSIndustries } from "../../store/actions/hls/hlsHome"
import { ECMEA_HEALTCARE_ID } from "../home/Home"

const IndustryPageContainer = styled.div`
  .container-left {
    padding: 50px 10px;
  }
`

const IndustryPage = () => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const location = useLocation()

  const industry = useSelector(state => state.hlsHero.industry)
  const industryArticles = useSelector(state => state.hlsHero.industryArticles)
  useDocumentTitle(
    `Industry |   ${
      industry?.name ? industry?.name : " "
    }    | Alvarez & Marsal`
  )
  useEffect(() => {
    dispatch(fetchHLSIndustries(ECMEA_HEALTCARE_ID))
    dispatch(fetchIndustry(state?.industryId))
    dispatch(fetchIndustryArticles(state?.industryUUID))
  }, [dispatch, state])

  const industries = useSelector(state => state?.hlsHero?.industries)

  //access directly from link
  if (!industry.name) {
    const URLLocation = location?.pathname?.split("/")[2]
    const industryName = URLLocation.split("-").join(" ").toUpperCase()
    let x = industries?.filter(x => x.name.toUpperCase() === industryName)
    dispatch(fetchIndustry(x[0]?.id))
    dispatch(fetchIndustryArticles(x[0]?.uuid))
  }
  if (!Object.entries(industry).length > 0) {
    return <Spinner />
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Industry',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <IndustryPageContainer>
        <HeroSection
          height={360}
          pageCategory={"Industry"}
          title={industry?.name}
          backgroundUrl={industry?.backgroundUrl}
        />
        <ContainerContent>
          <IndustryContainer>
            <div className="d-lg-flex  ">
              <div className="col-12 col-lg-8 container-left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: industry?.description ?? industry?.teaser_text,
                  }}
                  className="descriptionContainer"
                />
                <ExpertiseContainer id="industryExpertiseId">
                  <ExpertiseSection expertises={industry?.expertises} />
                </ExpertiseContainer>
              </div>
              <div className="expertsContainer py-5 col-12 col-lg-4  ps-lg-3 ps-xxl-5 container-right">
                {industry?.experts?.map((expert, index) => {
                  return (
                    <ProfessionalCard
                      key={index}
                      id="professional-card-industries"
                      name={expert.title}
                      imageSrc={expert?.image}
                      position={expert?.profession_title}
                      country={expert?.global_location}
                      expertLink={expert?.alias}
                      alias={expert?.url}
                      buttonText={"connect"}
                    />
                  )
                })}
                <div className="w-100">
                  <SocialsCardSmall className="socials-card-industries" />
                  <ContactSubscribeCard />
                </div>
              </div>
            </div>
          </IndustryContainer>
        </ContainerContent>
        {industryArticles.length !== 0 && (
          <FullWidthContainer bgColor={"var(--graySections)"} className="">
            <StyledContainer>
              <ArticlesSection articles={industryArticles} />
            </StyledContainer>
          </FullWidthContainer>
        )}
      </IndustryPageContainer>
    </>
  )
}

export default IndustryPage

const ExpertiseContainer = styled.div`
  padding-top: 2rem;
`
