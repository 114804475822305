import React from "react"
import { useNavigate } from "react-router"
import { Container, Row, Col } from "react-bootstrap"

import Spinner from "../../components/Spinner"
import { BigHomeServiceCard } from "../cards"
import styled from "styled-components/macro"
import { ButtonWithAnimation } from "../buttons"

export const CTA = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`

const BigHomeServiceSection = ({ expertises, buttonColor }) => {
  const navigate = useNavigate()

  const homeServices = expertises.filter(expertise => expertise.is_front)

  if (!Array.isArray(homeServices) || !homeServices.length) {
    return <Spinner />
  }

  return (
    <Container style={{ paddingTop: "50px" }}>
      <Row md={2} xl={4}>
        {homeServices?.map((expertise, index) => {
          return (
            <Col
              key={index}
              style={{ paddingBottom: "20px" }}
              className="d-flex justify-content-center"
            >
              <BigHomeServiceCard
                name={expertise?.name}
                backgroundUrl={expertise?.backgroundUrl}
                icon={expertise?.icon}
                content={expertise?.description}
                index={index}
                buttonColor={buttonColor}
                onClick={() =>
                  navigate(
                    `/services/${expertise?.name
                      ?.toLowerCase()
                      ?.split(" ")
                      ?.join("-")}`,
                    {
                      state: {
                        id: expertise?.id,
                        canonicalUrl: expertise?.canonicalUrl,
                      },
                    }
                  )
                }
              />
            </Col>
          )
        })}
      </Row>
      <CTA>
        <ButtonWithAnimation
          text={"Learn more about our full suite of services"}
          color={buttonColor}
          onClick={() => navigate(`/services`)}
        />
      </CTA>
    </Container>
  )
}

export default BigHomeServiceSection
