import React, { useRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux/es/exports"
import CellWithChevron from "./CellWithChevron"
import * as S from "./styles/Dropdown.styles"
import { addNavbarFilters } from "../../../store/actions/leaders"

const Dropdown = ({ text, index,array, iconColor, getRefDropdownContainer }) => {
  const dispatch = useDispatch()
  const dropdownRef = useRef()
  const dropdownHeightRef = useRef(null)

  const navbarFilters = useSelector(state => state.leaders.navbarFilters)

  const [isOpen, setIsOpen] = useState(false)

  // Listtening to clicks outside of the dropdown
  useEffect(() => {
    const closeDropdown = e => {
      if (!dropdownRef.current.contains(e.target) && isOpen) {
        setIsOpen(false)
      }
    }

    document.body.addEventListener("click", closeDropdown)

    return () => {
      document.body.removeEventListener("click", closeDropdown)
    }
  }, [isOpen])

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const addFilter = filts => {
    filts.category = text.name
    if (navbarFilters?.length > 0) {
      const found = navbarFilters.find(f => f.type === filts.type)
      if (!found) {
        dispatch(addNavbarFilters(filts))
      }
    } else {
      dispatch(addNavbarFilters(filts))
    }
    if (filts.category === text?.name) {
      setIsOpen(false)
    }
  }

  const handleColorDrop = () => {
    let color = ""
    navbarFilters?.forEach(el => {
      if (el?.category === text?.name) {
        color = "var(--yellowCategory)"
      }
    })
    return color
  }

  const colorDrop = handleColorDrop()

  //get ref of dropwdown container (get height)
  useEffect(() => {
    getRefDropdownContainer(dropdownHeightRef, isOpen)
  }, [isOpen, text?.values])

  // close dropwdown everytime user select a filter
  useEffect(() => {
    setIsOpen(false)
  }, [text?.values])

  return (
    <div ref={dropdownRef}>
      <CellWithChevron
        text={text?.name ?? text}
        handleClick={handleClick}
        dropdownOpened={isOpen}
        iconColor={iconColor}
        color={colorDrop}
      />
      <S.DropdownContainer
        isOpen={isOpen}
        ref={dropdownHeightRef}
      >
        {text?.values
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((filts, index, array) => (
        <>
          <TextNavbarFilter key={index} array={array} index={index} addFilter={addFilter} filts={filts} />
        </>
  ))}
      </S.DropdownContainer>
    </div>
  )
}

export default Dropdown

const TextNavbarFilter = ({ addFilter, array, filts, index }) => {
  const navbarFilters = useSelector(state => state.leaders.navbarFilters)
  const handleColorList = () => {
    let color = ""
    navbarFilters?.forEach(el => {
      if (el?.name === filts?.name) {
        color = "var(--yellowCategory)"
      }
    })
    return color
  }
  const textColor = handleColorList()
  return (
    <li
      onClick={() => {
        addFilter(filts)
      }}
    >
      <span style={{ color: textColor}}>{filts?.name}</span>
      {index !== array.length - 1 && <span style={{ color: "var(--light-blue)", paddingLeft: "1rem" }}>|</span>}    
      </li>
  )
}
