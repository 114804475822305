import Axios from "axios"
import { cleanPodcastsData, getLinkWithJsonApiFilters } from "./helper"

const jsonApi = process.env.REACT_APP_JSON_API_URL

export const getPodcasts = async (
  currentInsightType,
  filters,
  nextPodcastPage
) => {
  try {
    let link

    if (nextPodcastPage !== undefined) {
      link = nextPodcastPage
    } else {
      link = `${jsonApi}/node/podcast?include=field_teaser_image,field_bulletin_category,field_category&filter[field_industry.id]=ca12f9a8-bfed-4fc1-9a04-5cc560a91fff`
      link = getLinkWithJsonApiFilters(link, filters)
      
      if(currentInsightType.uuid){
        link += `filter[field_category.id]=${currentInsightType.uuid}`
      }

      link += "&page[limit]=30&filter[status]=1"
      link += "&sort[sort-created][path]=created&sort[sort-created][direction]=DESC"
    }

    const res = await Axios.get(link)

    const nextPageLink = (await res.data.links?.next?.href) || null

    const cleanData = await cleanPodcastsData(res.data)

    return { cleanData, nextPageLink }
  } catch (e) {
    console.log("This is e", e)
    return null
  }
}
