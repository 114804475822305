import Axios from "axios"
import { dateToShortLocale } from "../utils/dateFormat"
import { articleBodyFormmater } from "../utils/articleBodyFormmater"

import {
  getLinkWithFilters,
  grabRelatedPeople,
  cleanInsightsData,
} from "./helper"

const jsonApi = process.env.REACT_APP_JSON_API_URL
const customApi = process.env.REACT_APP_CUSTOM_API_URL
const apiUrl = process.env.REACT_APP_BASE_API_URL

export const getSearchResults = async (
  currentInsightType,
  filters,
  nextPage
) => {
  try {
    const link = `${customApi}/insight-filter?filter[insight][value][0]=${currentInsightType.id[0]}&filter[insight][value][1]=${currentInsightType.id[1]}&filter[insight][value][2]=${currentInsightType.id[2]}&filter[insight][operator]=IN&industryes[]=971&industryes[]=3006&industryes[]=3026&industryes[]=3021&industryes[]=3016&industryes[]=3011&page=${nextPage}`
    const linkWithFilters = getLinkWithFilters(link, filters)

    const res = await Axios.get(linkWithFilters)

    return cleanInsightsData(res.data, currentInsightType.name)
  } catch (e) {
    console.log("This is e", e)
    return null
  }
}

// Get insights from the api
export const getInsights = async (currentInsightType, filters, nextPage) => {
  try {

    let link = `${customApi}/hls-insights?`
    if(currentInsightType.name !== 'all insights'){
      link += `insight[]=${currentInsightType.id}&`
    }
    link += `page=${nextPage}`

    const linkWithFilters = getLinkWithFilters(link, filters)

    const res = await Axios.get(linkWithFilters)

    return cleanInsightsData(res.data)
  } catch (e) {
    console.log("This is e", e)
    return null
  }
}

export const getSingleArticle = async (id, language) => {
  try {
const includeString = 'field_authors.field_professional_title,field_authors.field_city,field_authors.field_image,field_authors.field_image_background,field_featured_expert.field_professional_title,field_featured_expert.field_city,field_featured_expert.field_image,field_featured_expert.field_image_background'    
    const url = `${jsonApi}/node/article/${id}?include=${includeString}`
    const translatedUrl = `${apiUrl}/${language}/jsonapi/node/article/${id}?include=${includeString}`

    let link = url

    if (language && language !== 'en') {
      link = translatedUrl
    }
    const res = await Axios.get(link)
    const data = res.data.data

    const article = {}

    article.content = articleBodyFormmater(data.attributes.body.processed)

    article.date = dateToShortLocale(
      data.attributes.created || data.attributes.changed 
    )

    article.id = data.attributes.id
    article.internalId = data.attributes.drupal_internal__nid
    article.title = data.attributes.title
    article.authors = grabRelatedPeople("field_authors", res.data, 0)
    article.experts = grabRelatedPeople("field_featured_expert", res.data, 0)

    const tagManagerLink = `${customApi}/hls/gtm/${id}`
    const tagManagerRes = await Axios.get(tagManagerLink)

    article.dataLayer = tagManagerRes.data

    return article
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getLocations = async setLocations => {
  const links = [
    `${apiUrl}/jsonapi/taxonomy_term/cities?include=field_countries_tag&filter[status_group][group][conjunction]=AND&filter[city_status][condition][path]=status&filter[city_status][condition][operator]=%3D&filter[city_status][condition][value]=1&filter[city_status][condition][memberOf]=status_group&filter[country_status][condition][path]=field_countries_tag.status&filter[country_status][condition][operator]=%3D&filter[country_status][condition][value]=1&filter[country_status][condition][memberOf]=status_group&page[offset]=0&page[limit]=50`,
    `${apiUrl}/jsonapi/taxonomy_term/cities?include=field_countries_tag&filter[status_group][group][conjunction]=AND&filter[city_status][condition][path]=status&filter[city_status][condition][operator]=%3D&filter[city_status][condition][value]=1&filter[city_status][condition][memberOf]=status_group&filter[country_status][condition][path]=field_countries_tag.status&filter[country_status][condition][operator]=%3D&filter[country_status][condition][value]=1&filter[country_status][condition][memberOf]=status_group&page[offset]=50&page[limit]=50`,
    `${apiUrl}/jsonapi/taxonomy_term/cities?include=field_countries_tag&&filter[status_group][group][conjunction]=AND&filter[city_status][condition][path]=status&filter[city_status][condition][operator]=%3D&filter[city_status][condition][value]=1&filter[city_status][condition][memberOf]=status_group&filter[country_status][condition][path]=field_countries_tag.status&filter[country_status][condition][operator]=%3D&filter[country_status][condition][value]=1&filter[country_status][condition][memberOf]=status_group&page[offset]=100&page[limit]=50`,
  ]

  const locationMap = new Map()

  const getCityData = async link => {
    try {
      const response = await Axios.get(link)
      if (!response.data) {
        console.log("response.data is empty")
        return
      }
      const data = response?.data?.data
      const included = response?.data?.included

      data.forEach(element => {
        const city = {
          preformatted: element.attributes.field_address_preformatted,
          city: element.attributes.name,
          phone: element.attributes.field_contact_phone,
          fax: element.attributes.field_fax_number,
          address_line1: element.attributes.field_address.address_line1,
          address_line2: element.attributes.field_address.address_line2,
          postal: element.attributes.field_address.postal_code,
          area: element.attributes.field_address.administrative_area,
          id: element.relationships.field_countries_tag.data[0].id,
          map: element.attributes.field_google_map_url,
        }

        if (locationMap.has(city.id)) {
          locationMap.get(city.id).cities.push(city)
        } else {
          locationMap.set(city.id, {
            country: null,
            cities: [city],
          })
        }
      })

      included.forEach(element => {
        const country = {
          country: element.attributes.name,
          idd: element.id,
        }

        if (locationMap.has(country.idd)) {
          locationMap.get(country.idd).country = country
        } else {
          locationMap.set(country.idd, {
            country,
            cities: [],
          })
        }
      })

      if (data.length === 50 && locationMap.size < 100) {
        const nextLinkIndex = links.indexOf(link) + 1
        if (nextLinkIndex < links.length) {
          await getCityData(links[nextLinkIndex])
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  try {
    await getCityData(links[0])

    const location = Array.from(locationMap.values()).map(item => ({
      city: item.cities.sort((a, b) => a.city.localeCompare(b.city)),
      country: item.country,
    }))

    location.sort((a, b) => a.country.country.localeCompare(b.country.country))
    setLocations(location)
  } catch (error) {
    console.error(error)
  }
}

export const getArticleTranslations = async (articleId) => {
  try {
    const link = `${customApi}/hls/translations/${articleId}`
    const res = await Axios.get(link)
    return res.data;

  } catch (error) {
    console.log(error)
    return []
  }
}