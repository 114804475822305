import React from "react"
import styled from "styled-components/macro"
import { ChevronRight } from "../../assets/icons"

const CardHeader = styled.div`
  background-color: var(--hover-blue);
  width: auto;
  height: auto;
  padding: 10px;
  border-bottom: 1px solid white;

  h5 {
    color: var(--white);
    text-transform: uppercase;
    margin: 0;
  }
`
const Card = styled.div`
  background-color: var(--darkBlueHome);
  width: auto;
  height: auto;
  margin-bottom: 20px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;

  .item {
    color: white;
  }
`
export const Container = styled.div`
  display: inline-flex;
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
`

export const ChevronContainer = styled.span`
  display: flex;
  align-items: flex-start;

  svg {
    width: 15px;
    height: 15px;
    fill: var(--hover-blue);
  }
`
export const TextContainer = styled.span`
  font-weight: bolder;
  display: flex;
  line-height: 1;
  align-items: center;
  position: relative;
`

const ValueToClientCard = values => {
  return (
    <>
      <CardHeader>
        <h5>Value to client</h5>
      </CardHeader>

      <Card>
        {values.values?.map((value, index) => {
          return (
            <Container key={index}>
              <ChevronContainer>
                <ChevronRight />
              </ChevronContainer>
              <TextContainer>
                <div className="item">{value}</div>
              </TextContainer>
            </Container>
          )
        })}
      </Card>
    </>
  )
}

export default ValueToClientCard
