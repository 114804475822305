import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { routeNames } from "../../routes/routes"
import { Search, XIcon, Logo, AlvarezMarsal } from "../../assets/icons"
import styled from "styled-components/macro"
import { sizem } from "../../utils/breakpoints"
import { useDispatch } from "react-redux"
import { addFilter } from "../../store/actions/filters"

const NavBar = ({ setNavbarActive, navbarActive }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [showSearchDiv, setShowSearchDiv] = useState(false)

  const industries = useSelector(state => state.hlsHero?.industries)
  const expertises = useSelector(state => state.hlsHero?.expertises)

  const logoUrl = 'https://www.alvarezandmarsal.com/';

  const handleSubmit = e => {
    e.preventDefault()

    const searchValue = e.target[0].value
    if (searchValue.trim() !== "") {
      navigate(`/search/${searchValue}`)
    }

    dispatch(
      addFilter({
        name: searchValue,
        category: "userInput",
      })
    )
    e.target[0].value = ""
  }

  const checkRoute = route => {
    const x =
      route.split("/")[1].toLowerCase() ===
      location.pathname.split("/")[1].toLowerCase()

    return x
  }
  const isMainPage = location.pathname === "/"

  const redirectToUrl = (url) => { 
    window.location.href = url;
  }

  return (
    <>
      <CustomNavbar className="py-0 " expand="lg">
        <Container
          fluid
          style={
            isMainPage ? { position: "absolute", zIndex: 1000, top: 0 } : {}
          }
        >
          <CustomNavbar.Brand href={logoUrl} className="logo">
            <Logo />
          </CustomNavbar.Brand>
          <div className="d-flex justify-content-around mx-3 align-items-center">
            <CustomNavbar.Toggle
              aria-controls="navbarScroll"
              onClick={() => {
                setNavbarActive(!navbarActive)
              }}
            />
          </div>

          <CustomNavbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0 fw-bold f navbarHLS">
              <Nav.Link className="active web-title " href={routeNames.home}>
                {"HOME"}
              </Nav.Link>
              <Nav.Link
                className="active web-title "
                href={routeNames.services}
                style={{
                  color: `${
                    checkRoute(routeNames?.services) ? "var(--hover-blue)" : ""
                  }`,
                }}
              >
                {"WHAT WE DO"}
              </Nav.Link>

              {/* <NavDropdown
                title={
                  <span
                    style={{
                      color: `${
                        checkRoute("/expertise") ? "var(--hover-blue)" : "white"
                      }`,
                    }}
                  >
                    Expertises
                  </span>
                }
                id="navbar-scrolling-dropdown"
              >
                {expertises?.map((expertise, index) => (
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(
                        `/expertise/${expertise?.name
                          ?.toLowerCase()
                          .split(" ")
                          .join("-")}`,
                        {
                          state: expertise?.id,
                        }
                      )
                    }}
                    key={index}
                  >
                    {(expertise?.name).toUpperCase()}
                  </NavDropdown.Item>
                ))}
              </NavDropdown> */}
              {/* <NavDropdown
                className=""
                title={
                  <span
                    style={{
                      color: `${
                        checkRoute("/industry") ? "var(--hover-blue)" : "white"
                      }`,
                    }}
                  >
                    Industries
                  </span>
                }
                id="navbar-scrolling-dropdown"
              >
                {industries?.map((industry, index) => (
                  <NavDropdown.Item
                    onClick={() => {
                      navigate(
                        `/industry/${industry?.name
                          ?.toLowerCase()
                          .split(" ")
                          .join("-")}`,
                        {
                          state: {
                            industryId: industry.id,
                            industryUUID: industry.uuid,
                          },
                        }
                      )
                    }}
                    key={index}
                  >
                    {industry?.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown> */}

              <Nav.Link
                className="active web-title"
                href={routeNames.leadership}
                style={{
                  color: `${
                    checkRoute(routeNames?.leadership)
                      ? "var(--hover-blue)"
                      : ""
                  }`,
                }}
              >
                {"WHO WE ARE"}
              </Nav.Link>

              <Nav.Link
                className="active web-title"
                href={routeNames.insights}
                style={{
                  color: `${
                    checkRoute(routeNames?.insights) ? "var(--hover-blue)" : ""
                  }`,
                }}
              >
                {"INSIGHTS"}
              </Nav.Link>

              <Nav.Link
                className="active web-title "
                href={routeNames.location}
                style={{
                  color: `${
                    checkRoute(routeNames?.location) ? "var(--hover-blue)" : ""
                  }`,
                }}
              >
                {"LOCATIONS"}
              </Nav.Link>
              <Nav.Link
                className="active web-title "
                href={"https://www.alvarezandmarsal.com/#block-connect-with-us"}
                target="_blank"
              >
                {"Contact Us"}
              </Nav.Link>
            </Nav>

            <Nav>
              <Nav.Link>
                <div>
                  <div className="rightIconsContainer">

                    <AlvarezMarsal 
                      id="AlvarezAndMarsal" 
                      onClick={()=> {redirectToUrl(logoUrl)}} 
                    />
                    
                    {showSearchDiv && (
                      <NavbarSearchContainer className="container-navbar-search">
                        <div className="region-header-short">
                          <div className="head-submenu-btns">
                            <div className="dsk-search">
                              <form onSubmit={e => handleSubmit(e)}>
                                <input placeholder="Search" />
                              </form>
                            </div>
                            {/* <a className="header-search-btn"></a>
                            <a className="menu-toggle"></a> */}
                          </div>
                        </div>
                      </NavbarSearchContainer>
                    )}
                    <div
                      className="d-flex justify-content-end"
                      style={{ width: "120px" }}
                    >
                      <CustomNavbar.Text>
                        {showSearchDiv ? (
                          <div
                            onClick={() => {
                              setShowSearchDiv(false)
                            }}
                          >
                            <XIcon />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShowSearchDiv(true)
                            }}
                          >
                            <Search />
                          </div>
                        )}
                      </CustomNavbar.Text>
                    </div>
                  </div>
                </div>
              </Nav.Link>
            </Nav>
          </CustomNavbar.Collapse>
        </Container>
      </CustomNavbar>
    </>
  )
}

export default NavBar

const CustomNavbar = styled(Navbar)`
  .logo {
    svg {
      height: 66.898px;
      width: 75.667px;
    }
  }
  #navbarScroll > div.me-auto.my-2.my-lg-0.fw-bold.f.navbar-nav > a {
    color: var(--white);
    font-size: 16px;
    letter-spacing: 1px;
    font-family: "Helvetica Neue LT Std", sans-serif;
    font-weight: 600;
    font-style: medium;
    text-transform: uppercase;
    @media ${sizem.smm} {
      font-size: 12px;
    }
  }

  .navbar-toggler {
    filter: grayscale(1) invert(1);
  }
  #navbar-scrolling-dropdown {
    color: inherit;
  }
  #navbar-scrolling-dropdown,
  .web-title {
    padding: 10px !important;
    font-size: 12px;
    font-family: "Helvetica Neue LT Std", sans-serif;
    font-weight: 300;
    font-style: medium;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media ${sizem.smm} {
      font-size: 12px;
    }
  }

  .nav-item.show.dropdown {
    @media ${sizem.smm} {
      font-size: 12px;
      margin-left: -20px;
      width: 102%;
    }
  }
  .nav-item.show.dropdown a {
    color: white !important;
    color: var(--white);
    @media ${sizem.smm} {
      font-size: 12px;
    }
  }
  .nav-item.show.dropdown div {
    background-color: #00254a;
    border-radius: 0;
    margin-top: -1px;
    padding-bottom: 0.5rem;
    border: 0;
    padding-top: 0px;
  }
  .nav-item.show.dropdown a {
    background-color: #00254a;
  }
  .container-icon {
    width: auto;
    margin-top: 0px !important;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active-link {
    background-color: #0085ca;
    color: var(--white);
  }
  .inactive-link {
    background-color: transparent;
    color: var(--hover-blue);
  }
  .rightIconsContainer {
    margin-top: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    .searchIcon {
      padding-top: 5px;
      width: inherit;
    }
    #AlvarezAndMarsal {
      width: 8rem;
    }
    svg {
      fill: white;
    }
  }
`
const NavbarSearchContainer = styled.div`
  .head-submenu-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }

  .head-submenu-btns .dsk-search {
    display: block;
    position: absolute;
    margin-right: 30px;
  }

  .head-submenu-btns .dsk-search input {
    border-width: 0;
    height: 30px;
    width: 100px;
    outline: none;
    border-bottom: 1px solid #00244a;
    background-color: transparent;
    color: white;
    font-weight: 100;
    border-bottom-color: white;
    position: absolute;
    z-index: 999;
    margin-left: 10px;
    &::placeholder {
      color: white;
    }
  }

  .menu-toggle {
    align-self: stretch;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
  }

  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`
