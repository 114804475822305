import React, { useEffect } from "react"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components/macro"
import BreadCrumb from "../buttons/BreadCrumb"
import ButtonWithAnimation from "../buttons/ButtonWithAnimation"
import NavBar from "../layout/NavBar"
import { InsightsNavbar } from "../navbars"
import * as S from "./HeroSection.styles"
import { useDispatch, useSelector } from "react-redux"
import { changeArticleLanguage } from "../../store/actions/articleLanguage"
import { getArticleTranslations } from "../../API/articles"

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`
const Icon = styled.div({
  [`svg`]: {
    width: "50px",
    height: "50px",
    fill: "var(--yellowCategory)",

    [`path, polyline`]: {
      stroke: "var(--yellowCategory)",
    },
  },
})

const HeroSection = ({
  title,
  description,
  backgroundUrl,
  icon,
  buttonText,
  buttonColor,
  pageCategory,
  pageCategoryCenter,
  className,
  route,
  date,
  height,
  Custom,
  locationButton,
  nid,
}) => {
  const location = useLocation()
  const [navbarActive, setNavbarActive] = useState(true)
  const [availableLanguages, setAvailableLanguages] = useState([])

  const { language } = useSelector(state => state.article)
  const dispatch = useDispatch()  

  useEffect(() => {
    if (!nid) return

    getArticleTranslations(nid).then(res => {
      if (
        !res ||
        !res.available_translations ||
        res.available_translations.length < 2
      ) {
        return
      }

      setAvailableLanguages(res.available_translations)
    })
    // eslint-disable-next-line
  }, [])

  const handleChangeArticleLanguage = lang => {
    dispatch(changeArticleLanguage(lang))
  }

  const languageButtonsElements = availableLanguages.map(languages => (
    <S.ChangeLanguage
      key={languages.lang}
      type="button"
      disabled={language === languages.lang}
      $active={language === languages.lang}
      onClick={() => handleChangeArticleLanguage(languages.lang)}
    >
      {languages.text}
    </S.ChangeLanguage>
  ))

  return (
    <>
      <S.Hero
        backgroundUrl={backgroundUrl}
        className={className}
        height={height}
      >
        <div className="contentContainer">
          <NavBar
            setNavbarActive={setNavbarActive}
            navbarActive={navbarActive}
          />
          {location.pathname === "/insights" && navbarActive ? (
            <InsightsNavbar />
          ) : (
            <>
              {navbarActive && (
                <div className="contentWithoutNavbar">
                  {pageCategory && (
                    <S.TitleRoute color={"var(--yellowCategory) "}>
                      {pageCategory?.toUpperCase()}
                    </S.TitleRoute>
                  )}
                  {route && (
                    <span>
                      <S.TitleRoute color={"var(--hover-blue) "}>
                        {route.route?.toUpperCase() + " / "}
                      </S.TitleRoute>
                      <S.TitleRoute color={"var(--yellowCategory) "}>
                        {route.subRoute?.toUpperCase()}
                      </S.TitleRoute>
                    </span>
                  )}
                  {pageCategoryCenter && (
                    <h4 className="text-center">{pageCategoryCenter}</h4>
                  )}
                  {date && <h4 className="text-white">{date}</h4>}
                  <Title>
                    <h1>{title}</h1>
                    {icon && (
                      <Icon>
                        <span dangerouslySetInnerHTML={{ __html: icon }} />
                      </Icon>
                    )}
                  </Title>

                  <S.CLanguageContainer>
                    {languageButtonsElements}
                  </S.CLanguageContainer>

                  {description && (
                    <p
                      className="pt-3 text-decoration-none"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></p>
                  )}

                  {buttonText && buttonColor && (
                    <div className="buttonContainer">
                      <ButtonWithAnimation
                        text={buttonText}
                        color={buttonColor}
                        href={"https://contact.alvarezandmarsal.com"}
                      />
                    </div>
                  )}
                  {Custom && <Custom location={locationButton} />}
                </div>
              )}
            </>
          )}
        </div>

        <BreadCrumb />
      </S.Hero>
    </>
  )
}

export default HeroSection
