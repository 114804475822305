import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { HeroSection } from "../../components/cards"
import { StyledContainerLocation } from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import LocationCardRow from "../../components/cards/LocationCardRow"
import { getLocations } from "../../API"
import TestButton from "../../components/buttons/TestButton"
import { Spinner } from "../../components"
import TagManager from "react-gtm-module"

import { LocationBannerImage } from "../../assets/images/index"

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const LocationPage = () => {
  const [location, setLocations] = useState(null)

  useEffect(() => {
    getLocations(setLocations)
  }, [])

  if (!location) {
    return <Spinner />
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Locations',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}/global-locations`} />
      </Helmet>

      <HeroSection
        height={360}
        backgroundUrl={LocationBannerImage}
        title={"HEALTHCARE & LIFE SCIENCES LOCATIONS"}
        description={
          "Cross border, no problem. Our global team of senior professionals are ready to execute your business solutions."
        }
        Custom={TestButton}
        locationButton={location}
      />
      <StyledContainerLocation>
        {location?.map((location, index) => (
          <LocationCardRow location={location} key={index} />
        ))}
      </StyledContainerLocation>
    </>
  )
}

export default LocationPage
