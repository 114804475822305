import React from "react"
import { ButtonWithAnimation } from "../buttons"
import styled from "styled-components/macro"
import DOMPurify from "dompurify"

const DisplayOver = styled.div({
  height: "100%",
  left: "0",
  position: "absolute",
  top: "0",
  width: "100%",
  zIndex: 2,
  transition: "background-color 350ms ease",
  backgroundColor: "transparent",
  padding: "20px 20px 0 20px",
  boxSizing: "border-box",
})

const Icon = styled.div({
  position: "absolute",
  paddingBottom: "95px",
  transform: "translate3d(0,0,0)",
  transition: "transform 350ms ease",

  [`svg`]: {
    width: "30px",
    height: "30px",
    fill: "var(--yellowCategory)",
  },
})

const Hover = styled.div({
  opacity: 0,
  transition: "opacity 350ms ease",
})

const Title = styled.h3({
  lineHeight: "25px",
  color: "var(--white)",
  fontSize: "1.5rem",
  fontWeight: "bold",
  transform: "translate3d(0,50px,0)",
  transition: "transform 350ms ease",
  marginTop: "145px",
})

const Paragraph = styled.p({
  color: "var(--white)",
  fontSize: "12px",
  transform: "translate3d(0,50px,0)",
  transition: "transform 350ms ease",
})

const CTA = styled.div({
  position: "absolute",
  bottom: "20px",
  left: "20px",
})

const Background = styled.div(props => ({
  position: "relative",
  color: "var(--darkBlue2)",
  width: "300px",
  height: "280px",
  background: [`url(${props.backgroundUrl || ""}), rgba(6, 25, 49, 0.5)`],
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundBlendMode: "multiply",

  [`:hover ${DisplayOver}`]: {
    backgroundColor: "rgba(0,0,0,.5)",
  },
  [`:hover ${Title}`]: {
    marginTop: "80px",
    transform: "translate3d(0,0,0)",
  },
  [`:hover ${Paragraph}`]: {
    transform: "translate3d(0,0,0)",
  },
  [`:hover ${Hover}`]: {
    opacity: 1,
  },
  [`:hover ${Icon}`]: {
    paddingBottom: "40px",
    transform: "translate3d(0,0,0)",
    transition: "transform 350ms ease",
  },
}))

const HomeServiceCard = ({
  name,
  backgroundUrl,
  icon,
  content,
  buttonText,
  buttonColor,
  onClick,
}) => {
  return (
    <Background backgroundUrl={backgroundUrl}>
      <DisplayOver>
        <Icon>
          <span dangerouslySetInnerHTML={{ __html: icon }} />
        </Icon>
        <Title onClick={() => onClick()}>{name}</Title>
        <Hover>
          <Paragraph>{DOMPurify.sanitize(content?.substr(0, 70))}...</Paragraph>
          <CTA>
            <ButtonWithAnimation
              text={buttonText || "Read more"}
              onClick={onClick}
              color={buttonColor}
            />
          </CTA>
        </Hover>
      </DisplayOver>
    </Background>
  )
}

export default HomeServiceCard
