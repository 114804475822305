import React from "react"
import { ButtonWithAnimation } from "../buttons"
import styled from "styled-components/macro"
import DOMPurify from "dompurify"

const Container = styled.div`
  margin-bottom: 3rem;

  border-bottom: 1px solid var(--hover-blue);

  color: var(--darkBlue2);
  padding-bottom: 1rem;
  padding-right: 20px;

  .icon-svg {
    position: absolute;
    margin-top: -35px;

    svg {
      width: 30px;
      height: 30px;
      fill: var(--hover-blue);
      margin-bottom: 10px;

      path, polyline {
        stroke: var(--hover-blue);
      }
    }
  }

  h3 {
    color: var(--darkBlueHome);
    font-size: 1.5rem;
    font-weight: bold;
    :hover {
      cursor: pointer;
    }
  }

  p {
    color: var(--darkBlueHome);
    font-size: 1rem;
  }
`
const ServiceCard = ({ name, icon, content, buttonText, onClick }) => {
  return (
    <Container>
      <div className="pb-4">
        <span className="icon-svg" dangerouslySetInnerHTML={{ __html: icon }} />
        <h3 onClick={() => onClick()}>{name}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content?.substr(0, 200)),
          }}
        ></p>

        <ButtonWithAnimation
          text={buttonText || "Read more"}
          onClick={onClick}
        />
      </div>
    </Container>
  )
}

export default ServiceCard
