import React from "react"
import styled from "styled-components/macro"
import { sizem } from "../../utils/breakpoints"

import { ApproachCard } from "../cards"
import { BorderBottom } from "../../components/cards/styles/ArticlePreviewCard.styles"
import UnalignedItemsContainer from "../layout/UnalignedItemsContainer"

const ApproachSectionContainer = styled.div`
  .special-container {
    padding: 0 10px;
  }
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  font-family: "Helvetica Neue LT Std", sans-serif;
  .grayContainer {
    h2 {
      font-size: 1.5rem;
      padding-top: 2rem;
    }
  }
  @media ${sizem.smm} {
    width: 100%;
    h2 {
      padding-top: 20px;
    }
  }
  .infoContainer {
    display: flex;
    flex-wrap: wrap;
  }

  h4 {
    color: var(--darkBlueHome);
    padding-top: 50px;
  }
`

const ApproachSection = ({ points, approachs }) => {
  return (
    <ApproachSectionContainer>
      <div className="grayContainer">
        <div className="special-container">
          {points && (
            <>
              <UnalignedItemsContainer columnsNumber={3}>
                {points?.map((point, index) => {
                  return (
                    <ApproachCard
                      key={index}
                      name={point.title}
                      content={point.text}
                    />
                  )
                })}
              </UnalignedItemsContainer>
            </>
          )}

          {approachs && (
            <>
              <BorderBottom color={"var(--hover-blue)"} />
              <h4>A&M's Approach</h4>
              <UnalignedItemsContainer columnsNumber={2}>
                {approachs?.map((approach, index) => {
                  return (
                    <ApproachCard
                      key={index}
                      icon={approach.image}
                      name={approach.title}
                      content={approach.text}
                    />
                  )
                })}
              </UnalignedItemsContainer>
            </>
          )}
        </div>
      </div>
    </ApproachSectionContainer>
  )
}

export default ApproachSection
