import styled from "styled-components/macro"
import { sizem } from "../../utils/breakpoints"

export const Slide = styled.div`
  .contentWithoutNavbar {
    position: relative;
    top: 160px;
    left: 30px;
    display: flex;
    flex-direction: column;

    .category {
      text-transform: uppercase;
      color: var(--yellowCategory);
    }

    .buttonContainer {
      margin-top: 120px;
    }

    h1 {
      color: #fff;
      text-shadow: 10px 10px 10px #00000029;
      font-family: "Helvetica Neue LT Std", sans-serif;
      font-style: medium;
      margin: 0;
      letter-spacing: 0.5px;
      font-size: 32px;
      line-height: 30px;
      text-transform: uppercase;

      @media ${sizem.smm} {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }

    p {
      font-family: "Helvetica Neue LT Std", sans-serif;
      font-style: medium;
      width: 65%;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #fff;

      @media ${sizem.smm} {
        width: 90%;
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
  }

  .firstContentContainer {
    max-width: 1065px;
    margin: 0 auto;
  }

  .secondContentContainer {
    max-width: 450px;
    a {
      text-decoration: none;
    }
    
    h1 {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .slideshow-container {
    width: 100%;
    overflow: hidden;

    .slick-list {
      margin: 0;
    }

    .slick-slide {
      margin: 0 10px;
      outline: none;
    }

    .slide-wrapper {
      display: flex;
      flex-direction: row;
      height: 500px;

      .each-slide {
        height: 100%;
        background-blend-mode: multiply;
        transition: all 1s ease;
      }

      .first-slide {
        width: 100%;
      }

      .second-slide {
        display: none;
        width: 33.33%;
      }
    }

    .indicators {
      display:none;
      margin-top: -7px;

      .indicator {
        position: relative;
        bottom: 30px;
        z-index: 99;
        cursor: pointer;
        height: 6px;
        width: 50px;
        border-width: 0;
        background-color: #fff;
        margin-right: 10px;
        color: transparent;
        user-select: none;
      }

      .indicator.active {
        background-color: #0085ca;
      }
    }
  }
  @media screen and (min-width: 991px) {
    .slideshow-container {
      .slide-wrapper {
        .first-slide {
          width: 66.67%;
        }
        .second-slide {
          display: block;
        }
      }

      .indicators {
        display: flex;
        justify-content: flex-end;
        margin-right: 19%;
      }

      // .indicator:first-child {
      //   width: 80px;
      //   margin-right: 50px;
      // }
    }
  }
  @media screen and (min-width: 1200px) {
    .slideshow-container .indicators {
      margin-right: 22%;
    }
  }
  @media screen and (min-width: 1500px) {
    .slideshow-container .indicators {
      margin-right: 25%;
    }
  }
`

export const TitleRoute = styled.span`
  font-size: 1.5rem;
  text-transform: "uppercase";
  color: ${props => props.color || "#fff"};
`
