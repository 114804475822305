import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import { useLocation } from "react-router-dom"
import { getIdByAlias, getSingleArticle } from "../../API"
import { useDocumentTitle } from "../../hook/useDocumentTitle"
import {
  ContactSubscribeCard,
  HeroSection,
  SocialsCard,
  FeaturedProfileCard,
} from "../../components/cards"
import { Spinner } from "../../components"
import { StyledContainer } from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import * as S from "./SingleInsgightPage.styles"
import DOMPurify from "dompurify"
import TagManager from "react-gtm-module"

import { InsightsBannerImage } from "../../assets/images/index"
import { useSelector } from "react-redux"

const SingleInsgightPage = () => {
  const { pathname, state } = useLocation()

  const [articleData, setArticleData] = useState(null)

  const { language } = useSelector(state => state.article)

  const getData = async lang => {
    if (!state) {
      let articleId = await getIdByAlias(pathname)
      const data = await getSingleArticle(articleId, lang)
      setArticleData(data)
    } else {
      const data = await getSingleArticle(state.uuid, lang)
      setArticleData(data)
    }
  }

  useEffect(() => {
    getData()
  }, [state, pathname])

  useEffect(() => {
    getData(language)
  }, [language])

  useDocumentTitle(articleData?.title)

  if (!articleData) {
    return <Spinner />
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: articleData.dataLayer
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={state?.canonicalUrl} />
      </Helmet>

      <HeroSection
        nid={articleData.internalId.toString()}
        title={articleData?.title || "No title"}
        backgroundUrl={InsightsBannerImage}
        date={articleData?.date || "No date"}
        route={{
          route: "health & life insigths",
          subRoute: "health & life case studies",
        }}
      />
      <StyledContainer>
        <S.Container>
          <S.Article
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(articleData?.content),
            }}
          ></S.Article>
          <S.RightSection>
            {articleData?.authors && (
              <>
                <h3>AUTHORS</h3>
                {articleData?.authors &&
                  articleData?.authors.map((author, index) => (
                    <FeaturedProfileCard {...author} key={index} />
                  ))}
              </>
            )}

            {articleData?.experts && (
              <>
                <h3>FEATURED PROFILES</h3>

                {articleData?.experts &&
                  articleData?.experts.map((expert, index) => (
                    <FeaturedProfileCard {...expert} key={index} />
                  ))}
              </>
            )}
            <ContactSubscribeCard internalId={articleData?.internalId} />
            <SocialsCard internalId={articleData?.internalId} />
          </S.RightSection>
        </S.Container>
      </StyledContainer>
    </>
  )
}

export default SingleInsgightPage