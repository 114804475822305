import React from "react"
import styled from "styled-components/macro"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Card = styled.div`
  background-color: var(--graySections);
  margin: 20px 0;
  padding: 20px;
  max-width: 400px;
`

export const TextMedia = styled.div`
  color: #0ba0cf;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  @media (min-width: 400px) and (max-width: 524px) {
    font-size: 14px;
  }
  @media (min-width: 928px) and (max-width: 1040px) {
    font-size: 14px;
  }
`

export const ShareItem = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 2.063rem;
    width: auto;
  }
`;

export const ShareIcons = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px 2px;
`

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const SocialsCard = props => {
  const shareList = [
    {
      url: "https://www.linkedin.com/company/alvarez-&-marsal/",
      icon: <FaLinkedinIn />,
      alt: "Linkedin",
    },
    {
      url: "https://www.facebook.com/alvarezandmarsal",
      icon: <FaFacebookF/>,
      alt: "Facebook",
    },
    {
      url: "https://twitter.com/alvarezmarsal",
      icon: <FaXTwitter/>,
      alt: "Twitter",
    },
    {
      url: "https://www.instagram.com/alvarezandmarsal_life/",
      icon: <FaInstagram/>,
      alt: "Instagram",
    },
    {
      url: "https://www.youtube.com/user/AlvarezMarsal",
      icon: <FaYoutube/>,
      alt: "Youtube",
    },
  ]

  const sendByMail = `${BASE_LINK}/printmail/${props.internalId}`

  return (
    <Card>
      <TextMedia>
        <div>FOLLOW & CONNECT WITH A&M</div>
      </TextMedia>
      <ShareIcons>
      
      {shareList.map((share, index) => (
            <ShareItem
              key={index}
              style={{background: share.icon.type === MdEmail ? "var( --black1)" : "var(--hover-blue)" }}
            >
              <a href={share.url} target="_blank" className="iconMedia" style={{color: share.icon.type === MdEmail ? "var( --black1)" : "var(--hover-blue)"}}>
                  {share.icon}
              </a>
            </ShareItem>
          ))}
        {props.internalId && (
          <ShareItem
          style={{background: "var( --black1)"}}
        >
            <a href={sendByMail} className="iconMedia" style={{color: "var( --black1)"}}>
                <MdEmail/>
            </a>
          </ShareItem>
        )}
      </ShareIcons>
    </Card>
  )
}

export default SocialsCard