import styled from "styled-components/macro"
import { size } from "../../../utils/breakpoints"

export const LeaadersContainer = styled.div`
  padding-top: 30px;
  display: grid;
  gap: 0.5rem;

  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 30px;
  @media ${size.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
  }
  @media ${size.lg} {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
  @media ${size.xl} {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
  }
`
