import React, { useMemo } from "react"
import { ButtonWithAnimation } from "../buttons"
import { useNavigate } from "react-router"
import { routeNames } from "../../routes/routes"
import styled from "styled-components/macro"
import moment from "moment"

const DisplayOver = styled.div({
  height: "100%",
  left: "0",
  position: "absolute",
  top: "0",
  width: "100%",
  zIndex: 2,
  transition: "background-color 350ms ease",
  backgroundColor: "transparent",
  padding: "40px",
  boxSizing: "border-box",
})

const Title = styled.h3({
  lineHeight: "25px",
  color: "var(--white)",
  fontSize: "1.5rem",
  fontWeight: "bold",
  transform: "translate3d(0,0,0)",
  transition: "transform 350ms ease",
})

const Date = styled.p({
  color: "var(--white)",
  fontSize: "12px",
  transform: "translate3d(0,50px,0)",
  transition: "transform 350ms ease",
})

const Paragraph = styled.p({
  color: "var(--white)",
  fontSize: "14px",
  transform: "translate3d(0,50px,0)",
  transition: "transform 350ms ease",
})

const CTA = styled.div({
  position: "absolute",
  bottom: "20px",
  left: "40px",
})

const Background = styled.div(props => ({
  position: "relative",
  color: "var(--darkBlue2)",
  height: "420px",
  background: [`url(${props.backgroundUrl || ""}), rgba(0, 37, 73, 1)`],
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundBlendMode: "multiply",
}))

const VideoPreviewContainer = styled.div({
  position: "relative",
  paddingBottom: "56.25%",
  height: "0",
  overflow: "hidden",
  marginTop: "20px",
})
const Iframe = styled.iframe({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
})

const MediaPreview = ({
  name,
  uuid,
  date,
  textTeaser,
  videoUrl,
  backgroundUrl,
  buttonText = "Read more",
  buttonColor,
  alias,
  canonicalUrl,
}) => {
  const navigate = useNavigate()
  const formattedDate = useMemo(
    () => moment.unix(date).format("MMM D, YYYY"),
    [date]
  )

  const videoUrlMatch =
    videoUrl && videoUrl.match(/(?:youtube.com\/watch\?v=|youtu.be\/)(\w+)/)
  const isYouTubePlayer = Boolean(videoUrlMatch)
  const isSoundCloudPlayer =
    !isYouTubePlayer && videoUrl && videoUrl.includes("w.soundcloud.com/player")
  const playerUrl = isYouTubePlayer
    ? `https://www.youtube.com/embed/${videoUrlMatch[1]}`
    : videoUrl

  const iframeAttributes = {
    title: isYouTubePlayer ? "YouTube video player" : "SoundCloud player",
    allow:
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true,
    rel: "nofollow",
  }

  const handleClick = () => {
    navigate(`${routeNames.insights}/${alias}`, {
      state: { uuid, canonicalUrl },
    })
  }

  return (
    <>
      <Background backgroundUrl={backgroundUrl}>
        <DisplayOver>
          <Title>{name}</Title>
          {videoUrl && (
            <VideoPreviewContainer>
              {isSoundCloudPlayer ? (
                <iframe width="100%" height="166" src={playerUrl}></iframe>
              ) : (
                <Iframe
                  width="560"
                  height="315"
                  src={playerUrl}
                  {...iframeAttributes}
                ></Iframe>
              )}
            </VideoPreviewContainer>
          )}
          {!videoUrl && (
            <>
              <Date>{formattedDate}</Date>
              <Paragraph>{textTeaser}</Paragraph>
            </>
          )}
          <CTA>
            <ButtonWithAnimation
              text={buttonText}
              color={buttonColor}
              onClick={handleClick}
            />
          </CTA>
        </DisplayOver>
      </Background>
    </>
  )
}

export default MediaPreview
