import {
  GET_HERO_SECTION_DATA,
  GET_SLIDES,
  GET_COUNTER_LIST,
  GET_CATEGORIES,
  GET_INDUSTRIES,
  GET_INDUSTRY,
  GET_EXPERTISES,
  GET_EXPERTISE,
  GET_INSIGHTS_ARTICLES,
  GET_RECENT_RECOGNITION,
  GET_INDUSTRY_ARTICLES,
} from "../../actions/hls/hlsHome"

const initialState = {
  heroSection: {},
  industries: [],
  expertises: [],
  industry: {},
  expertise: {},
  insightsArticles: [],
  recentRecognition: [],
  industryArticles: [],
}

const hlsHero = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HERO_SECTION_DATA: {
      return {
        ...state,
        heroSection: payload,
      }
    }
    case GET_SLIDES: {
      return {
        ...state,
        slides: payload,
      }
    }
    case GET_COUNTER_LIST: {
      return {
        ...state,
        frontCounter: payload,
      }
    }
    case GET_CATEGORIES: {
      return {
        ...state,
        categories: payload,
      }
    }
    case GET_INDUSTRIES: {
      return {
        ...state,
        industries: payload,
      }
    }
    case GET_EXPERTISES: {
      return {
        ...state,
        expertises: payload,
      }
    }
    case GET_INDUSTRY: {
      return {
        ...state,
        industry: payload,
      }
    }
    case GET_EXPERTISE: {
      return {
        ...state,
        expertise: payload,
      }
    }
    case GET_INSIGHTS_ARTICLES: {
      return {
        ...state,
        insightsArticles: payload,
      }
    }
    case GET_RECENT_RECOGNITION: {
      return {
        ...state,
        recentRecognition: payload,
      }
    }
    case GET_INDUSTRY_ARTICLES: {
      return {
        ...state,
        industryArticles: payload,
      }
    }
    default:
      return state
  }
}

export default hlsHero
