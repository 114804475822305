import React, { useRef, useEffect, useState } from "react"
import CellWithChevron from "./CellWithChevron"
import * as S from "./styles/Dropdown.styles"
import { useDispatch, useSelector } from "react-redux"
import { addFilter } from "../../../store/actions/filters"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = ({ text, filters, category, colors }) => {
  const dropdownRef = useRef()
  const dispatch = useDispatch()
  const dropdownHeightRef = useRef(null)
  const { filters: selectedFilters } = useSelector(state => state.filters)

  // Determines the names of the filters that should be highlighted
  const highlightFilterNames = []
  selectedFilters.forEach(selectedFilter => {
    highlightFilterNames.push(selectedFilter.name)
    highlightFilterNames.push(selectedFilter.category)
  })

  const [isOpen, setIsOpen] = useState(false)

  // Listtening to clicks outside of the dropdown
  useEffect(() => {
    const closeDropdown = e => {
      if (!dropdownRef.current.contains(e.target) && isOpen) {
        setIsOpen(false)
      }
    }

    document.body.addEventListener("mousedown", closeDropdown)

    return () => {
      document.body.removeEventListener("mousedown", closeDropdown)
    }
  }, [isOpen])

  const addFilterToRedux = filter => {
    dispatch(addFilter(filter))
  }

  const highlightCell = name => {
    return highlightFilterNames.includes(name)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div ref={dropdownRef}>
      <CellWithChevron
        text={text}
        handleClick={handleClick}
        dropdownOpened={isOpen}
        highlight={highlightFilterNames.includes(category)}
        colors={colors}
      />
      {category === "date" ? (
        <S.CalendarWrapper isOpen={isOpen} ref={dropdownHeightRef}>
          <TimeDropdown
            filters={filters}
            addFilterToRedux={addFilterToRedux}
            category={category}
            highlightCell={highlightCell}
            colors={colors}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </S.CalendarWrapper>
      ) : (
        <S.DropdownContainer isOpen={isOpen} ref={dropdownHeightRef}>
          <NormalDropdown
            filters={filters}
            addFilterToRedux={addFilterToRedux}
            category={category}
            highlightCell={highlightCell}
            colors={colors}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </S.DropdownContainer>
      )}
    </div>
  )
}

const NormalDropdown = ({
  filters,
  category,
  addFilterToRedux,
  highlightCell,
  colors,
  isOpen,
  setIsOpen
}) => {
  return filters.map((filter, index) => {
    const closeDropdown = () => {
      if (isOpen) {
        setIsOpen(false)
      }
    }

    const addToRedux = () => {
      addFilterToRedux({
        id: filter.id,
        name: filter.name,
        category: category,
        uuid: filter.uuid,
      })
      closeDropdown();
    }

    return (
      <S.DropdownItem
        onClick={addToRedux}
        highlight={highlightCell(filter.name)}
        key={index}
        style={{
          "--textColor": colors.textColor,
          "--highlightColor": colors.highlightColor,
        }}
      >
        {filter.name}
      </S.DropdownItem>
    )
  })
}

const TimeDropdown = ({
  addFilterToRedux,
  category,
  isOpen,
  setIsOpen
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const closeDropdown = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const addToRedux = () => {
    if(startDate !== null && endDate !== null){
      addFilterToRedux({
        category: category,
        name: `${startDate.toLocaleDateString()} : ${endDate.toLocaleDateString()}`,
        minDate: formatDate(startDate),
        maxDate: formatDate(endDate)
      })

      closeDropdown();
      setDateRange([null, null]);
    }
  }

  useEffect(() => {
    addToRedux();
  }, [dateRange]);

  return ( 
    <DatePicker
    selectsRange={true}
    startDate={startDate}
    endDate={endDate}
    onChange={(update) => {
      setDateRange(update);
    }}
    inline
  />
  )
}

export default Dropdown;
