import React from "react"
import styled from "styled-components/macro"
import { sizem } from "../../utils/breakpoints"
import { useNavigate } from "react-router"

import { ServiceCard } from "../cards"
import UnalignedItemsContainer from "../layout/UnalignedItemsContainer"

const ServiceSectionContainer = styled.div`
  .special-container {
    padding: 0 10px;
  }
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  font-family: "Helvetica Neue LT Std", sans-serif;
  .grayContainer {
    h2 {
      font-size: 1.5rem;
      padding-top: 2rem;
    }
    h3 {
      :hover,
      :active {
        cursor: pointer;
        /* transform: scale(1.01, 1.01); */
        color: var(--hover-blue);
      }
    }
  }
  @media ${sizem.smm} {
    width: 100%;
    h2 {
      padding-top: 20px;
    }
  }
  .infoContainer {
    display: flex;
    flex-wrap: wrap;
  }
`

const ServiceSection = ({ expertises }) => {
  const navigate = useNavigate()
  return (
    <ServiceSectionContainer>
      <div className="grayContainer">
        <div className="special-container">
          <UnalignedItemsContainer columnsNumber={3}>
            {expertises?.map((expertise, index) => {
              return (
                <ServiceCard
                  key={index}
                  name={expertise?.name}
                  icon={expertise?.icon}
                  content={expertise?.description}
                  index={index}
                  onClick={() =>
                    {navigate(
                        `/services/${expertise?.name
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`,
                        {
                          state: {
                            id: expertise?.id,
                            canonicalUrl: expertise?.canonicalUrl,
                          },
                        }
                      )
                    }
                  }
                />
              )
            })}
          </UnalignedItemsContainer>
        </div>
      </div>
    </ServiceSectionContainer>
  )
}

export default ServiceSection
