import {
  GET_ALL_LEADERS,
  GET_FILTER_TAGS,
  FILTER_LEADERS,
  GET_ALL_PERSONS,
  GET_FILTERED_PERSONS,
  ADD_NAVBAR_FILTERS,
  FILTER_NAVBAR_FILTERS,
  LOADING_PERSONS,
  LOADING_LEADERS,
  GET_MORE_PERSONS,
} from "../actions/leaders"

const initialState = {
  leaders: [],
  industryPersons: [],
  cities: [],
  expertises: [],
  location: [],
  filteredLeaders: [],
  personsCities: [],
  personsExpertises: [],
  personsLocation: [],
  personsIndustries: [],
  filtersPersons: [],
  morePersons: [],
  navbarFilters: [],
  fetchLeaders: false,
  fetchPersons: false,
}

const leadersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PERSONS:
      return {
        ...state,
        fetchPersons: payload,
      }
    case LOADING_LEADERS:
      return {
        ...state,
        fetchLeaders: payload,
      }
    case GET_ALL_LEADERS:
      return {
        ...state,
        leaders: payload.leaders,
        cities: payload.cities,
        expertises: payload.expertises,
        location: payload.location,
        filteredLeaders: payload.leaders,
      }
    case GET_ALL_PERSONS:
      state.filtersPersons.forEach(filter => {
        payload?.filters?.forEach(payloadFilter => {
          if (
            filter.name.toLowerCase() === payloadFilter.name.toLowerCase() &&
            payloadFilter?.values?.length > 0
          ) {
            let o = [...payloadFilter?.values, ...filter?.values]
            payloadFilter.values = o.filter(
              (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
            )
          }
        })
      })
      let j = payload.filters

      return {
        ...state,
        industryPersons: payload.profiles,
        filtersPersons: j[0].values ? j : state.filtersPersons,
        personsCities: payload.cities,
        personsExpertises: payload.expertises,
        personsLocation: payload.location,
        personsIndustries: payload.industries,
      }
    case GET_FILTERED_PERSONS:
      state.filtersPersons.forEach(filter => {
        payload?.filters?.forEach(payloadFilter => {
          if (
            filter.name.toLowerCase() === payloadFilter.name.toLowerCase() &&
            payloadFilter?.values?.length > 0 &&
            payload.loadMore
          ) {
            let o = [...payloadFilter?.values, ...filter?.values]
            payloadFilter.values = o.filter(
              (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
            )
          }
        })
      })
      let x = payload.filters

      return {
        ...state,
        industryPersons: payload.profiles,
        filtersPersons: x[0].values ? x : state.filtersPersons,
        personsCities: payload.cities,
        personsExpertises: payload.expertises,
        personsLocation: payload.location,
        personsIndustries: payload.industries,
      }
    case GET_MORE_PERSONS:
      return {
        ...state,
        morePersons: state.persons.concat(payload),
      }
    case GET_FILTER_TAGS: {
      return {
        ...state,
        filterTags: payload,
      }
    }
    case FILTER_LEADERS:
      return {
        ...state,
        filteredLeaders: payload,
      }
    case ADD_NAVBAR_FILTERS:
      return {
        ...state,
        navbarFilters: state?.navbarFilters?.concat(payload),
      }
    case FILTER_NAVBAR_FILTERS:
      return {
        ...state,
        navbarFilters: state.navbarFilters.filter(
          filter => filter.id !== payload.id
        ),
      }

    default:
      return state
  }
}

export default leadersReducer
