import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { HeroSection, ProfessionalsCardSmallOld } from "../../components/cards"
import TagManager from "react-gtm-module"

import { useDocumentTitle } from "../../hook"
import { Spinner } from "../../components"

import { useDispatch, useSelector } from "react-redux"
import {
  fetchHLSLeaders,
  fetchHLSPersons,
  filterHLSPersons,
} from "../../store/actions/leaders"
import * as S from "../../components/navbars/leadershipNavbar/styles/InsightsNavbar.styles"

import Dropdown from "../../components/navbars/leadershipNavbar/Dropdown"
import { InsightsNavbarContext } from "../../components/navbars/leadershipNavbar/InsightsNavbar"
import CellWithChevron from "../../components/navbars/leadershipNavbar/CellWithChevron"
import FiltersContainer from "../../components/navbars/leadershipNavbar/FiltersContainer"
import {
  StyledContainerLocation,
  FullWidthContainer,
} from "../../components/layout/Rows&Collumns/Rows&Collumns.style"

import { LeaadersContainer } from "./style/Leadership.style"
import InfiniteScroll from "react-infinite-scroll-component"

import { ProfessionalsBannerImage } from "../../assets/images/index"

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const Leadership = () => {
  const dispatch = useDispatch()

  const leaders = useSelector(state => state.leaders.filteredLeaders)
  const personsUnsorted = useSelector(state => state.leaders?.industryPersons)
  const persons = personsUnsorted.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const filtersPersons = useSelector(state => state?.leaders?.filtersPersons)

  const navbarFilters = useSelector(state => state.leaders.navbarFilters)

  const [openedState, setOpenedState] = useState(
    Array.from(leaders ?? [], () => false)
  )
  const [openedStatePersons, setOpenedStatePersons] = useState(
    Array.from(persons ?? [], () => false)
  )

  const handleDisplay2 = (index, array, state, setState) => {
    if (!state[index]) {
      let arr = Array.from(array, () => false)
      arr[index] = true
      setState([...arr])
    } else {
      setState(Array.from(array, () => false))
    }
  }

  useEffect(() => {
    dispatch(fetchHLSLeaders())
  }, [dispatch])

  useDocumentTitle("Leadership | Alvarez & Marsal")

  const handleClick = () => {
    setShowNavbar(!showNavbar)
  }

  const [dropdownHeight, setDropdownHeight] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [showNavbar, setShowNavbar] = useState(false)

  const values = {
    dropdownHeight: dropdownHeight,
    setDropdownHeight: setDropdownHeight,
    setSelectedFilters: setSelectedFilters,
    selectedFilters: selectedFilters,
  }

  const updateState = (setState, state) => {
    setState(state)
  }
  const getMorePersons = () => {
    let j = limit
    let x = j * 2
    setOffset(j)
    setLimit(x)
    dispatch(fetchHLSPersons(j, x))
  }
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)

  useEffect(() => {
    if (navbarFilters.length === 0) {
      setLimit(50)
      setOffset(0)

      // dispatch(fetchHLSPersons(0, 50))
    }
  }, [dispatch, navbarFilters.length])

  const [offsetFiltered, setOffsetFiltered] = useState(0)
  const [limitFiltered, setLimitFiltered] = useState(50)
  const [industryPersons, setIndustryPersons] = useState([])

  useEffect(() => {
    setIndustryPersons([...industryPersons, ...persons])
  }, [persons])

  useEffect(() => {
    updateState(setIndustryPersons, [])
    updateState(setLimitFiltered, 50)
    updateState(setOffsetFiltered, 0)

    dispatch(filterHLSPersons(0, 50, false))
  }, [navbarFilters.length, dispatch])

  const getMoreFilteredPersons = () => {
    if (navbarFilters.length >= 1) {
      let j = limitFiltered
      let x = j * 2

      dispatch(filterHLSPersons(j, x, true))
      setOffsetFiltered(j)
      setLimitFiltered(x)
    }
  }

  const [marginTopFilterNavbar, setMarginTopFilterNavbar] = useState(0)
  const getRefDropdownContainer = (x, state) => {
    if (x.current.clientHeight !== 0) {
      setMarginTopFilterNavbar(x.current.clientHeight)
    }
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Leadership',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }
  
  TagManager.dataLayer(tagManagerArgs)

  return (
    <FullWidthContainer bgColor="white">
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}/our-people`} />
      </Helmet>

      <HeroSection
        title={"Health & Life Sciences Professionals"}
        description={
          "Wherever you are, we have industry leaders to solve your problems."
        }
        className="heroContainer"
        backgroundUrl={ProfessionalsBannerImage}
        height={360}
      />
      <StyledContainerLocation className="my-2">
        {leaders?.length === 0 ? (
          <Spinner />
        ) : (
          <div>
            <InsightsNavbarContext.Provider value={values}>
              <CellWithChevron
                text={"Filter by"}
                handleClick={handleClick}
                onlyMobile={true}
              />
              <S.Navbar showNavbar={showNavbar}>
                {filtersPersons?.map((term, index, array) => (
                  <Dropdown
                    text={term}
                    iconColor={"var(--hover-blue)"}
                    key={index}
                    index={index}
                    array={array}
                    getRefDropdownContainer={getRefDropdownContainer}
                  />
                ))}
              </S.Navbar>
              <FiltersContainer
                filtercolor={"var(--hover-blue)"}
                // marginTop={`${marginTopFilterNavbar}px`}
              />
            </InsightsNavbarContext.Provider>

            <h1 className="">Healthcare and Life Sciences Professionals</h1>

            <div className="">
              <LeaadersContainer>
                {leaders?.map((cardInfo, index) => {
                  return (
                    <ProfessionalsCardSmallOld
                      className="col-lg-4"
                      key={index}
                      cardInfo={cardInfo}
                      index={index}
                      openedState={openedState}
                      handleDisplay={() =>
                        handleDisplay2(
                          index,
                          leaders,
                          openedState,
                          setOpenedState
                        )
                      }
                      showDetails={openedState[index]}
                    />
                  )
                })}
              </LeaadersContainer>
              {/* <h1 className=" mt-4" id="leadershipLeadersId">
                Team
              </h1> */}
              {navbarFilters.length === 0 ? (
                <InfiniteScroll
                  dataLength={persons.length}
                  hasMore={persons.length > 0}
                  next={getMorePersons}
                  loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                >
                  <LeaadersContainer style={{ paddingTop: "20px" }}>
                    {industryPersons?.map((cardInfo, index) => {
                      return (
                        <ProfessionalsCardSmallOld
                          key={index}
                          cardInfo={cardInfo}
                          index={index}
                          openedState={openedState}
                          handleDisplay={() =>
                            handleDisplay2(
                              index,
                              persons,
                              openedStatePersons,
                              setOpenedStatePersons
                            )
                          }
                          showDetails={openedStatePersons[index]}
                        />
                      )
                    })}
                  </LeaadersContainer>
                </InfiniteScroll>
              ) : (
                <InfiniteScroll
                  dataLength={persons.length}
                  hasMore={persons.length > 0}
                  next={getMoreFilteredPersons}
                  loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                >
                  <LeaadersContainer>
                    {industryPersons?.map((cardInfo, index) => {
                      return (
                        <ProfessionalsCardSmallOld
                          key={index}
                          cardInfo={cardInfo}
                          index={index}
                          openedState={openedState}
                          handleDisplay={() =>
                            handleDisplay2(
                              index,
                              persons,
                              openedStatePersons,
                              setOpenedStatePersons
                            )
                          }
                          showDetails={openedStatePersons[index]}
                        />
                      )
                    })}
                  </LeaadersContainer>
                </InfiniteScroll>
              )}
            </div>
          </div>
        )}
      </StyledContainerLocation>
    </FullWidthContainer>
  )
}

export default Leadership
