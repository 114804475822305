import React, { createContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Spinner from "../../components/Spinner"
import { HeroSection } from "../../components/cards"
import { ServicesBannerImage } from "../../assets/images/index"
import TagManager from "react-gtm-module"

import { StyledContainer } from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import ServiceSection from "../../components/section/ServiceSection"

import { fetchHlsExpertises } from "../../store/actions/hls/hlsHome"
import { Helmet } from "react-helmet"

export const ContentContext = createContext()

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const Services = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchHlsExpertises())
  }, [dispatch])

  const expertises = useSelector(state => state?.hlsHero?.expertises)

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Services',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}/services`} />
      </Helmet>

      <HeroSection
        title="Services"
        backgroundUrl={ServicesBannerImage}
        description="A&M can support you with a comprehensive suite of services:"
      />

      <StyledContainer>
        {expertises?.length !== 0 ? (
          <ServiceSection expertises={expertises} />
        ) : (
          <Spinner />
        )}
      </StyledContainer>
    </>
  )
}

export default Services
