import React, { createContext } from "react"
import { Helmet } from "react-helmet"
import { HeroSection } from "../../components/cards"
import { StyledContainer } from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import { useSelector } from "react-redux"
import HealthPodcasts from "./HealthPodcasts"
import ArticleInsightType from "./ArticleInsightType"
// import MultipleInsightTypes from "./MultipleInsightTypes.jsx"
import { InsightsBannerImage } from "../../assets/images/index"
import TagManager from "react-gtm-module"

export const ContentContext = createContext()

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const Insights = () => {
  const { currentInsightType } = useSelector(state => state.filters);
  const currentMediaType = useSelector(state => state.filters.filters.find(filter => filter.category === 'media') || {name: 'All', id: 'all'});

  // Object with all components. The key names of the object are important. they are the equal to currentMediaType.id value
  const mediaTypeObj = {
    all: <ArticleInsightType insightType={currentInsightType} />,
    article: <ArticleInsightType insightType={currentInsightType} />,
    video: <ArticleInsightType insightType={currentInsightType} />,
    podcast: <HealthPodcasts insightType={currentInsightType} />,
  }

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': 'Insights',
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}/insights`} />
      </Helmet>

      <HeroSection backgroundUrl={InsightsBannerImage} />
      <StyledContainer>
        {mediaTypeObj[currentMediaType.id]}
      </StyledContainer>
    </>
  )
}

export default Insights
