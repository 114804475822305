export const articleBodyFormmater = (body) => {

    const siteUrl = process.env.REACT_APP_BASE_API_URL;

    // Search for img tags and change the src to the correct one
    const imgRegex = /<img[^>]+src="([^">]+)"/g

    // Add the site url to the src
    body = body.replace(imgRegex, (match, src) => {
        return match.replace(src, `${siteUrl}${src}`)
    })

    return body;
}