import React from "react"
import { ChevronRight } from "../../assets/icons"
import styled from "styled-components/macro"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 2rem;

  border-bottom: 1px solid var(--hover-blue);

  color: var(--darkBlue2);
  padding-bottom: 1rem;
  padding-right: 20px;
  h3 {
    color: var(--darkBlueHome);
    font-size: 1.5rem;
    font-weight: bold;
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  p {
    color: var(--darkBlueHome);
    font-size: 1rem;
    margin-bottom: 5px;
  }
`
export const CContainer = styled.div`
  display: inline-flex;
`

export const ChevronContainer = styled.span`
  display: flex;
  align-items: flex-start;
  padding-top: 5px;

  svg {
    width: 15px;
    height: 15px;
    fill: var(--hover-blue);
  }
`
export const TextContainer = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  p {
    z-index: 2;
  }
`
const ApproachCard = ({ icon, name, content }) => {
  return (
    <Container>
      {icon && <img src={icon} width="32" height="32" alt={name}></img>}
      <div className="pb-4">
        <h3>{name}</h3>
        {content.map((items, index) => {
          return (
            <div className="item" key={index}>
              {items.map((item, index) => {
                return (
                  <CContainer key={index}>
                    <ChevronContainer>
                      <ChevronRight />
                    </ChevronContainer>
                    <TextContainer>
                      <p>{item}</p>
                    </TextContainer>
                  </CContainer>
                )
              })}
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default ApproachCard
