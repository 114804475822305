import React from "react"
import { useState, useEffect, memo, useMemo } from "react"
import styled from "styled-components/macro"
import BreadCrumb from "../buttons/BreadCrumb"
import NavBar from "../layout/NavBar"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import * as S from "./SlideSection.styles"
import { useNavigate } from "react-router"
import { routeNames } from "../../routes/routes"

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`

const defaultSlides = [
  { backgroundUrl: "", eyebrow: "", title: "", link: null },
]

const SlideSection = memo(({ slides = defaultSlides, pageCategory, route }) => {
  const navigate = useNavigate()
  const [navbarActive, setNavbarActive] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [shouldAutoplay, setShouldAutoplay] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setShouldAutoplay(window.innerWidth > 991)
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleBeforeChange = (previousIndex, nextIndex) => {
    setCurrentIndex(nextIndex)
  }

  const slideProperties = useMemo(
    () => ({
      duration: 4000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      pauseOnHover: true,
      canSwipe: true,
      autoplay: shouldAutoplay,
    }),
    [shouldAutoplay]
  )

  const indicators = useMemo(
    () => index => <div className="indicator">{index + 1}</div>,
    []
  )

  const handleClick = (uuid, canonicalUrl) => {
    const alias = canonicalUrl.substring(canonicalUrl.lastIndexOf("/") + 1)
    navigate(`${routeNames.insights}/${alias}`, {
      state: { uuid, canonicalUrl },
    })
  }

  return (
    <S.Slide>
      <div className="slideshow-container">
        <div style={{ maxWidth: "1600px", margin: "0 auto" }}>
          <NavBar
            setNavbarActive={setNavbarActive}
            navbarActive={navbarActive}
          />
        </div>
        <Fade {...slideProperties} indicators={indicators}>
          {slides?.map(({bannerTitle, bannerTeaser, bannerImage, backgroundUrl, eyebrow, title, link }, index) => {
            return (
              <div key={index} className="slide-wrapper">
                <div
                  className={`each-slide first-slide`}
                  style={{
                    background: `url(${bannerImage}) center center / cover no-repeat, rgba(6, 25, 49, 0.5)`,
                  }}
                >
                  <div className="firstContentContainer">
                    {navbarActive && (
                      <div className="contentWithoutNavbar">
                        {pageCategory && (
                          <S.TitleRoute color={"var(--yellowCategory) "}>
                            {pageCategory?.toUpperCase()}
                          </S.TitleRoute>
                        )}
                        {route && (
                          <span>
                            <S.TitleRoute color={"var(--hover-blue) "}>
                              {route.route?.toUpperCase() + " / "}
                            </S.TitleRoute>
                            <S.TitleRoute color={"var(--yellowCategory) "}>
                              {route.subRoute?.toUpperCase()}
                            </S.TitleRoute>
                          </span>
                        )}

                        <h5 className="category">
                          {bannerTitle}
                        </h5>
                        <Title>
                          <h1>{bannerTeaser}</h1>
                        </Title>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`each-slide second-slide`}
                  style={{
                    background: `url(${backgroundUrl}) center center / cover no-repeat, rgba(6, 25, 49, 0.5)`,
                  }}
                >
                  <div className="secondContentContainer">
                    {navbarActive && (
                      <div className="contentWithoutNavbar">
                        <h5 className="category">{eyebrow}</h5>
                        <div
                          onClick={() =>
                            handleClick(
                              link?.data?.uuid,
                              link?.link["#attributes"]["href"]
                            )
                          }
                        >
                          <Title style={{ cursor: "pointer" }}>
                            <h1>{title}</h1>
                          </Title>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Fade>
      </div>

      <BreadCrumb />
    </S.Slide>
  )
})

export default SlideSection
