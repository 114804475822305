import React from "react"
import styled from "styled-components/macro"
import { ButtonWithAnimation } from "../buttons"

const Card = styled.div`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 40px;
  display: flex !important;
  flex-direction: column !important;
  
  .city {
    text-align: start;
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    color: #00244a;
    margin-bottom: 30px;
  }
  .contact-field {
    font-size: 20px;
    line-height: 24px;
    color: #00244a;
    margin-bottom: 40px;
  }
  .phone{
    margin-top: 20px;
  }
  .address-line {
    // margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

const LocationCard = ({ location }) => {

  console.log(location);

  const {
    preformatted,
    address_line1,
    address_line2,
    area,
    country,
    postal,
    city,
    phone,
    fax,
    map,
  } = location

  return (
    <>
      <Card>
        <div className="city">{city}</div>
        <div className="contact-field">
          {
            // Check if preformatted is filled in the CMS and overwrite the address if it is
            preformatted !== null ?(
              <div className="address-line" dangerouslySetInnerHTML={{ __html: preformatted.value }} />
            ) :
            <>
              <div className="adress">
                <div className="address-line">{address_line1}</div>
                <div className="address-line">{address_line2}</div>
                <div className="address-line">{area}</div>
                <div className="address-line">{postal}</div>
                <div className="address-line">{country}</div>
              </div>
              <div className="phone contact-field">
                {
                  phone !== null ? (
                    <div className="address-line">Tel: {phone}</div>
                  ) : null
                }
                {
                  fax !== null ? (
                    <div className="address-line">Fax: {fax}</div>
                  ) : null
                }
              </div>  
            </>
          }
        </div>
        <ButtonWithAnimation text={"VIEW MAP"} href={map} />
      </Card>
    </>
  )
}

export default LocationCard
