import {
  Home,
  Services,
  SingleServicePage,
  Insights,
  SingleInsgightPage,
  ExpertisePage,
  IndustryPage,
  Leadership,
  Location,
  SearchPage,
} from "../pages/"

export const routeNames = {
  home: "/",

  services: "/services",
  serviceCaseArticlePage: "/services/:id",

  insights: "/insights",
  insightCaseArticlePage: "/insights/:id",

  expertise: "/expertise",
  expertisePage: "/expertise/:id",
  industryPage: "/industry/:id",

  leadership: "/leadership",

  location: "/location",

  searchPage: "/search/:id",
}

export const routes = [
  {
    path: routeNames.home,
    exact: true,
    element: <Home />,
  },

  {
    path: routeNames.services,
    exact: true,
    element: <Services />,
  },
  {
    path: routeNames.serviceCaseArticlePage,
    exact: true,
    element: <SingleServicePage />,
  },

  {
    path: routeNames.insights,
    exact: true,
    element: <Insights />,
  },
  {
    path: routeNames.insightCaseArticlePage,
    exact: true,
    element: <SingleInsgightPage />,
  },

  {
    path: routeNames.expertisePage,
    exact: true,
    element: <ExpertisePage />,
  },
  {
    path: routeNames.industryPage,
    exact: true,
    element: <IndustryPage />,
  },

  {
    path: routeNames.leadership,
    exact: true,
    element: <Leadership />,
  },
  {
    path: routeNames.location,
    exact: true,
    element: <Location />,
  },
  {
    path: routeNames.searchPage,
    exact: true,
    element: <SearchPage />,
  },
]

export default routes
