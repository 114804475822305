import React from "react"
import styled from "styled-components/macro"
import {
  Facebook,
  LinkedIn,
  TwitterX,
  Instagram,
  YouTube,
} from "../../assets/icons"

const Card = styled.div`
  background-color: var(--graySections);
  margin: 20px 0;
  padding: 20px;
  max-width: 400px;

  .icon {
    cursor: pointer;
    color: #fff;
  }

  img {
    height: 2.8rem;
    width: auto;
  }

  .blue-box,
  .black-box {
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--hover-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .black-box {
    background-color: #000;
  }

  .white-circle {
    background-color: #ffffff;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const TextMedia = styled.div`
  color: #0ba0cf;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  @media (min-width: 400px) and (max-width: 524px) {
    font-size: 14px;
  }
  @media (min-width: 928px) and (max-width: 1040px) {
    font-size: 14px;
  }
`

export const ShareIcons = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const SocialsCardSmall = () => {
  const shareList = [
    {
      url: "https://www.linkedin.com/company/alvarez-&-marsal/",
      icon: (
        <div className="white-circle">
          <LinkedIn className="icon" />
        </div>
      ),
      alt: "Linkedin",
    },
    {
      url: "https://www.facebook.com/alvarezandmarsal",
      icon: <Facebook className="icon" />,
      alt: "Facebook",
    },
    {
      url: "https://twitter.com/alvarezmarsal",
      icon: (
        <div className="white-circle">
          <TwitterX className="icon" />
        </div>
      ),
      alt: "Twitter",
    },
    {
      url: "https://www.instagram.com/alvarezandmarsal_life/",
      icon: (
        <div className="white-circle">
          <Instagram className="icon" />
        </div>
      ),
      alt: "Instagram",
    },
    {
      url: "https://www.youtube.com/user/AlvarezMarsal",
      icon: <YouTube className="icon" />,
      alt: "Youtube",
    },
  ]

  return (
    <Card>
      <TextMedia>
        <div>FOLLOW & CONNECT WITH A&M</div>
      </TextMedia>
      <ShareIcons>
        {shareList.map((share, index) => (
          <div className="box blue-box" key={index}>
            <a href={share.url} target="_blank" rel="noreferrer">
              {share.icon}
            </a>
          </div>
        ))}
      </ShareIcons>
    </Card>
  )
}

export default SocialsCardSmall
