import React from "react"
import { ChevronDownWhite } from "../../../assets/icons"
import * as S from "./styles/Cell.styles"

const CellWithChevron = ({
  text,
  onTouchStart,
  handleClick,
  onKeyDown,
  onlyMobile,
  dropdownOpened,
  iconColor,
  color,
}) => {
  return (
    <S.NavbarCell
      onTouchStart={() => onTouchStart()}
      onKeyDown={() => onKeyDown()}
      onClick={() => handleClick()}
      onlyMobile={onlyMobile}
      dropdownOpened={dropdownOpened}
      iconColor={color ? color : iconColor}
    >
      <span style={{ color: color }}>{text}</span>
      <ChevronDownWhite />
    </S.NavbarCell>
  )
}

export default CellWithChevron
