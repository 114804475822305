import {
  LinkedIn,
  Location2SVG,
  Mail2,
  Phone,
  Twitter,
  Vcard,
} from "../../../assets/icons"
import { size, sizem } from "../../../utils/breakpoints"
import styled from "styled-components/macro"

export const Card = styled.div`
  background-image: url(https://www.alvarezandmarsal.com/sites/default/files/countrypage_us.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 0;
  left: 0;
  //height: -webkit-fill-available;

  min-height: 478px;
  width: 100%;
  max-width: 1580px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;

  :after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 36, 74, 0.9);
  }
  .xicon {
    position: absolute;
    right: 0;
    z-index: 3;
    top: 15px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-size: 32px 32px;
    margin-right: 15px !important;
  }
  @media ${sizem.mdm_m} {
    height: ${props => (props.display !== "none" ? "750px" : "550px")};
  }
  @media ${sizem.smm} {
    padding: 5px;
    height: ${props => (props.display !== "none" ? "800px" : "550px")};
  }
`
export const XContainer = styled.a`
  display: inline-block;
  margin: 0 0 0 auto;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--hover-blue);
  }
  :hover {
    svg {
      fill: #fff;
    }
  }
`

export const CardBody = styled.div`
  gap: 2rem;

  position: relative;

  @media ${size.lg} {
    position: relative;
    display: flex;
    z-index: 2;
    padding: 20px;
    height: 478px;
  }
`

export const Header = styled.div`
  margin-right: 30px;
`

export const ImageContainer = styled.div`
  height: 100%;

  @media ${sizem.mdm_m} {
    position: relative;
    z-index: 100;
    float: left;

    margin: 2px;
    height: 200px;
    width: 150px;
  }
  @media ${sizem.smm} {
    margin: 20px 0px;
    padding: 20px 0px;
    height: 200px;
    width: 150px;
    z-index: 1000;
    position: absolute;
    /* border: 2px solid red; */
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;

    object-position: 100% 10%;
    width: 296px;
    aspect-ratio: auto 296 / 434;

    @media ${sizem.xlm} {
      /* margin-top: -100px; */
      width: 200px;
      height: 70%;
    }

    @media ${sizem.mdm_m} {
      /* width: 200px; */
      height: 100%;
    }
    @media ${sizem.smm} {
      /* padding: 20px; */
      /* margin-top: 100px; */
    }
  }
`
export const ImageTriangleContainer = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  padding-left: 40%;
  padding-top: 15px;
  svg {
    fill: #00244a;
  }
  /* border-bottom: 14px solid #00244a; */
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media ${sizem.mdm_m} {
    /* border: 2px solid red; */
    position: relative;
    z-index: 100;
    margin: 5px 10px;
    padding: 5px 10px;

    /* margin: 2px; */
    /* height: 200px;
    width: 150px; */
  }

  @media ${sizem.smm} {
    /* margin-top: -100px; */
    /* border: 2px solid red; */
    position: relative;
    z-index: 1000;
    font-size: 18px;
    /* margin-top: 35px; */
    padding-top: 45px;
    margin-right: 25px;
  }
  /* justify-content: space-between; */
  h4 {
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
    @media ${sizem.mdm_m} {
      text-align: center;
    }
    @media ${sizem.smm} {
      /* margin-top: -100px; */
      font-size: 14px !important;
      text-align: right;
      font-size: 16px;
    }
  }
  h6 {
    font-size: 18px;
    line-height: 1;
    color: var(--hover-blue);
    text-transform: uppercase;
    @media ${sizem.mdm_m} {
      text-align: center;
    }
    @media ${sizem.smm} {
      /* margin-top: -100px; */
      text-align: right;
      font-size: 16px;
    }
  }
  p {
    font-size: 16px;
    color: #fff;
    margin: 20px 0;
    @media ${sizem.xlm} {
      width: 102%;

      /* margin-top: -100px; */

      font-size: 14px;
    }
    @media ${sizem.lgm} {
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
  }
  #button-leaders {
    margin-top: auto;
    margin-bottom: 20px;
    @media ${sizem.mdm_m} {
      display: none;
    }
    @media ${sizem.smm} {
      /* margin-top: -100px; */
      display: none;
      /* margin-bottom: 40px; */
    }
  }

  /* flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h4 {
    font-weight: bold;
    color: #fff;
  }
  h6 {
    font-weight: bold;
    color: var(--hover-blue);
  }

  & :nth-child(2) {
    font-weight: bold;
    text-transform: uppercase;
  }

  a {
    font-weight: bold;
  }
  p {
    color: #fff;
  } */
`

export const Info = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  min-width: 300px;
  @media ${sizem.mdm_m} {
    /* / text-align: center; */
    /* border: 2px solid green; */
    position: relative;
    z-index: 100;
    /* width: 50%; */
    margin-top: 50px;
    margin-left: -5px;
    margin-right: 10px;
    /* margin: 0px 10px; */
    color: #fff;
    display: flex;
    justify-content: center;
  }
  @media ${sizem.smm} {
    margin-top: 20px;
    /* border: 2px solid yellow; */
    position: relative;
    z-index: 1000;
    color: #fff;
  }
`

export const InfoCard = styled.div`
  font-weight: bold;
  /* border-bottom: 1px solid black; */
  padding: 0.5rem 0;

  .iconDetail {
    width: 20px;
    margin-right: 10px;
  }

  .iconContainer {
    display: flex;
  }

  @media ${sizem.xlm} {
    /* margin-top: -100px; */

    font-size: 16px;

    .iconContainer {
      // justify-content: center;
    }
  }
  a {
    text-decoration: none;
    color: #6bbeec;
  }
  p {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 0;
  }
`

export const Dropdown = styled.div`
  font-size: 20px;
  color: #6bbeec;
  text-transform: uppercase;
  display: grid;
  gap: 20px;
  margin: auto 0;
  display: ${props => props.display};
  /* border: 2px solid red; */
  //margin-bottom: 40px;
  .mobileConnect {
    display: none;
  }

  @media ${size.lg} {
    display: initial;
  }
  @media ${sizem.mdm_m} {
    text-align: center;
    .mobileConnect {
      display: block;
    }
  }
  @media ${sizem.smm} {
    /* padding: 0px 10px; */
    gap: 0px;
    text-align: center;
    margin-bottom: 20px;
    .mobileConnect {
      display: block;
    }
  }
`

export const ContactInfo = styled.div`
  width: 100%;
  /* margin: 1rem 1rem; */
  margin-right: 5%;
  margin-left: 2%;
  padding: 1rem 0;

  border-top: 1px solid #fff;
  border-bottom: ${props => (props.borderBottom ? "1px solid #fff" : "none")};

  text-align: center;

  @media ${size.lg} {
    display: none;
  }
`

export const PersonalInfo = ({ title, content, onClick, icon }) => {
  return (
    <InfoCard>
      <div onClick={() => onClick()} className="iconContainer">
        {title === "email" ? (
          <>
            {icon && icon === "mail" && <Mail2 className="iconDetail" />}
            <a href={content?.uri}>
              <span>{content.title} </span>
            </a>
          </>
        ) : (
          <>
            {icon && icon === "linkedin" && <LinkedIn className="iconDetail" />}
            {icon && icon === "location" && (
              <Location2SVG className="iconDetail" />
            )}
            {icon && icon === "twitter" && <Twitter className="iconDetail" />}
            {icon && icon === "vcard" && <Vcard className="iconDetail" />}
            {icon && icon === "phone" && <Phone className="iconDetail" />}
            <span>{content}</span>
          </>
        )}
      </div>
    </InfoCard>
  )
}

export const DescriptionDesktop = styled.div`
  display: none;

  @media ${size.sm} {
    display: initial;
  }
`

export const ContainerMobile = styled.div`
  margin: 2rem 0;

  button {
    margin-bottom: 2rem;
  }
  @media ${sizem.smm} {
    /* margin-top: -100px; */
    padding-top: 50px;
    padding-left: 5px;
    padding-right: 5px;
    /* padding: 50px 10px; */
    /* height: 1; */
  }

  @media ${size.sm} {
    display: none;
  }
`
/*********************************** */

export const TopArrowImage = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  padding-left: 40%;
  padding-top: 15px;
  svg {
    fill: #00244a;
  }
`
export const DetailCard = styled.div`
  background-image: url(https://www.alvarezandmarsal.com/sites/default/files/countrypage_us.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  position: absolute;
  right: 0;
  left: 0;

  min-height: 1030px;

  width: 100%;
  max-width: 1580px;

  margin: 15px auto;

  :after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 36, 74, 0.9);
  }

  display: flex;
  justify-content: space-between;
  padding: 20px;

  .card-col-data {
    width: 50px;
    margin-right: 20px;
    z-index: 100;
    flex: 1;

    .employee-photo {
      min-height: 434px;
      img {
        width: 100%;
        height: 434px;
        object-fit: cover;

        object-position: 100% 10%;
        aspect-ratio: auto 296 / 434;
      }
    }

    .employee-data {
      margin-top: 30px;

      .employee-title {
        h4 {
          font-size: 36px;
          color: #fff;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 0px;
        }

        h6 {
          font-size: 20px;
          line-height: 1;
          color: #0085c9;
          text-transform: uppercase;
        }
      }

      .employee-description {
        p {
          font-size: 20px;
          color: #fff;
          margin: 20px 0;
        }
      }
    }

    .employee-contact {
      span {
        font-size: 20px;
      }
      margin-top: 30px;
      color: #6bbeec;
      text-transform: uppercase;
      transition: color 0.2s ease-out;
      text-decoration: none;
    }
  }

  .card-col-close-icon {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    z-index: 100;

    .close-icon {
      width: 40px;
      height: 40px;

      svg {
        width: 40px;
        height: 40px;
        filter: invert(28%) sepia(81%) saturate(2769%) hue-rotate(182deg)
          brightness(99%) contrast(100%);
      }
    }
  }

  @media ${sizem.mdm_m} {
    //height: ${props => (props.display !== "none" ? "750px" : "550px")};
  }
  
  @media ${sizem.mdm} {
    width: 98%;

    .employee-photo{

      width: 296px;
    }
  }

  @media ${sizem.smm} {
    //padding: 5px;
    //height: ${props => (props.display !== "none" ? "800px" : "550px")};
  }

  @media ${size.md} {
    min-height: auto;

    .card-col-data {
      display: flex;
      flex-wrap: wrap;

      .employee-contact {
        margin-top: 0px;
      }

      .employee-data {
        max-width: 280px;
        margin-left: 20px;
        margin-top: 0px;
        max-height: 434px;

        display: flex;
        flex-direction: column;

        .employee-description {
          flex: 1;
        }
      }
    }

    .employee-photo {
      max-width: 296px;
      height: 434px;

      img {
        max-height: 434px;
      }
    }
  }

  @media ${size.lg} {
    .card-col-data {
      .employee-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .employee-data {
        flex: 1;
        margin-right: 30px;
        max-width: inherit;
      }
    }
  }
`
