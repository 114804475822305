import React from "react"
import styled from "styled-components/macro"
import { ServicesBannerImage } from "../../assets/images/index"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 300px;

  background: url(${props => props.backgroundImage || ""}), rgba(6, 25, 49, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;

  @media screen and (min-width: 991px) {
    height: 250px;
  }
`

export const Content = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px 10px;
`

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: 20px;
  left: 30px;

  @media screen and (min-width: 576px) {
    top: 50px;
    align-items: center;
  }

  @media screen and (min-width: 991px) {
    flex-direction: row;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 25px;

  @media screen and (min-width: 991px) {
    flex-direction: column;
    margin-right: 25px;
  }

  @media screen and (min-width: 1300px) {
    margin-right: 100px;
  }
`

export const Text = styled.div`
  color: #fff;
  text-shadow: 10px 10px 10px #00000029;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-style: medium;
  margin: 0;
  letter-spacing: 0.5px;
  font-size: 1.8rem;
  line-height: 2rem;
  text-transform: uppercase;

  &:first-of-type {
    margin-right: 25px;
  }

  @media screen and (min-width: 1300px) {
    &:first-of-type {
      white-space: nowrap;
      margin-right: 100px;
    }
  }

  @media screen and (min-width: 991px) {
    font-size: 2rem;
  }
`
export const Number = styled.div`
  color: var(--yellowCategory);
  text-shadow: 10px 10px 10px #00000029;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-style: medium;
  margin: 0;
  letter-spacing: 0.5px;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
`

const GlanceSection = ({ counterData }) => {
  const title = counterData?.title
  const items = counterData?.list

  return (
    <>
      {title && items && (
        <Container backgroundImage={ServicesBannerImage}>
          <Content>
            <Items>
              <Text>{title}</Text>
              {items?.slice(0, 5).map((item, index) => {
                return (
                  <Item key={index}>
                    <Number>{item.counter}</Number>
                    <Text>{item.label}</Text>
                  </Item>
                )
              })}
            </Items>
          </Content>
        </Container>
      )}
    </>
  )
}

export default GlanceSection
