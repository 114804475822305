import React from "react"
import styled from "styled-components/macro"
import LocationCard from "./LocationCard"
import { ContainerLocation } from "../layout/Rows&Collumns/Rows&Collumns.style"

const Card = styled.div`
  width: 100%;
  .country {
    text-align: start;
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
    color: #0085ca;
    text-transform: uppercase;
    margin: 40px 0;
  }
`

const LocationCardRow = ({ location }) => {
  const { country } = location

  return (
    <Card>
      <div className="country" id={country.idd}>
        {country.country}
      </div>
      <ContainerLocation>
        {location.city?.map((location, index) => (
          <LocationCard location={location} key={index} />
        ))}
      </ContainerLocation>
    </Card>
  )
}

export default LocationCardRow
