import React from "react"
import styled from "styled-components/macro"
import { ButtonWithAnimation } from "../buttons"

const Card = styled.div`
  background-color: var(--graySections);
  width: auto;
  height: auto;
  margin: 20px 0;
  padding: 20px;
  .buttons-container {
    .button2,
    .button3 {
      padding-top: 20px;
    }
  }
`

const ContactSubscribeCard = props => {

  const BASE_LINK = process.env.REACT_APP_BASE_API_URL

  return (
    <Card>
      <div className="buttons-container">
        <div className="button1">
          <ButtonWithAnimation
            text={"CONTACT US TODAY"}
            href={"https://www.alvarezandmarsal.com/#block-connect-with-us"}
            target="_blank"
          />
        </div>
        <div className="button2">
          <ButtonWithAnimation
            text={"SUBSCRIBE TO OUR BULLETIN"}
            color={"var(--black1)"}
            href={"https://www.alvarezandmarsal.com/content/dont-miss-beat"}
            target="_blank"
          />
        </div>
        {props.internalId && (
          <div className="button3">
            <ButtonWithAnimation
              text={"DOWNLOAD PDF VERSION"}
              color={"var(--black1)"}
              href={`${BASE_LINK}/printpdf/${props.internalId}`}
              target="_blank"
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export default ContactSubscribeCard