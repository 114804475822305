import styled from "styled-components/macro"
import { size, sizem } from "../../../utils/breakpoints"

export const Container = styled.div`
  padding-bottom: ${({ padding }) => padding + "px" || 0};
  cursor: pointer;

  h1{
    font-weight: 900;
    font-size: 24px;
  }
`

export const Card = styled.div`
  display: flex;
  background: #002b49;
  min-height: 180px;
  max-height: 225px;
`

export const ImageContainer = styled.div`
  background: gray;
  flex: 2;

  @media ${sizem.mdm} {

    max-width: 150px;
    min-height: 225px;
  }

  @media ${size.sm} {
    flex: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    object-position: 100% 10%;
  }
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2.2;
`

export const Content = styled.div`
  padding: 20px 10px;
  text-transform: uppercase;
  color: var(--orange2);
  font-weight: bold;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  p{

    padding: 0;
  }

  a, p{

    font-weight: normal;
    font-size: 20px;
    line-height: 1;
  }

  a:first-child {
    font-weight: 900;
    font-size: 24px;
  }
`
