import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
// import HeroSection from "../../components/heroSection/HeroSection"
import SlideSection from "../../components/slideSection/SlideSection"
import GlanceSection from "../../components/section/GlanceSection"
import TagManager from "react-gtm-module"

import BigHomeServiceSection from "../../components/section/BigHomeServiceSection"
import FeaturedMediaSection from "../../components/section/FeaturedMediaSection"
// import HomeServiceSection from "../../components/section/HomeServiceSection"
// import RecongnitionSection from "../../components/section/RecongnitionSection"
// import ArticlesSection from "../../components/section/ArticlesSection"

import styled from "styled-components/macro"

import { fetchData } from "../../store/actions/hls/hlsHome"

import { StyledContainer } from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
// import { Spinner } from "../../components"

export const ECMEA_HEALTCARE_ID = "c11b8f8f-9d3a-433a-949e-5518b9b24c25"

const HomeContainer = styled.div`
  .heroContainer {
    margin: 0;
    padding: 0;
  }
`

// const ArticlesContainer = styled.div`
//   /* padding: 50px 10px; */
//   width: 100%;
//   background-color: var(--graySections);
//   color: var(--darkBlueHome);
//   /* background-color: #f2f2f2; */
// `

const BASE_LINK = process.env.REACT_APP_BASE_API_URL

const Home = () => {
  const dispatch = useDispatch()
  const heroData = useSelector(state => state?.hlsHero?.heroSection)
  const expertises = useSelector(state => state?.hlsHero?.expertises)
  const frontCounter = useSelector(state => state?.hlsHero?.frontCounter)
  const slides = useSelector(state => state?.hlsHero?.slides)
  const categories = useSelector(state => state?.hlsHero?.categories)

  // const insightsArticles = useSelector(
  //   state => state?.hlsHero?.insightsArticles
  // )
  // const recentRecognition = useSelector(
  //   state => state?.hlsHero?.recentRecognition
  // )

  useEffect(() => {
    dispatch(fetchData())
  }, [dispatch])

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <HomeContainer>
      <Helmet>
        <link rel="canonical" href={`${BASE_LINK}`} />
      </Helmet>

      {/* <HeroSection
        title={heroData?.title}
        backgroundUrl={heroData?.backgroundUrl}
        description={heroData?.description}
        buttonText="talk to us to learn more"
        buttonColor={"var(--yellowCategory)"}
        height={450}
      /> */}
      <SlideSection slides={slides} />
      {/* <GlanceSection
        counterData={frontCounter}
        backgroundUrl={heroData?.backgroundUrl}
      /> */}

      {expertises && (
        <BigHomeServiceSection
          expertises={expertises}
          buttonColor={"var(--yellowCategory)"}
          backgroundUrl={heroData?.backgroundUrl}
        />
      )}

      {categories && <FeaturedMediaSection categories={categories} />}

      {/* {expertises.length > 0 ? (
        <StyledContainer>
          <HomeServiceSection
            expertises={expertises}
            buttonColor={"var(--yellowCategory)"}
          />
          {recentRecognition.length > 0 && (
            <RecongnitionSection
              arr={recentRecognition}
              titleSection="Recent Recognition"
            />
          )}
        </StyledContainer>
      ) : (
        <Spinner />
      )} */}

      {/* <ArticlesContainer>
        <StyledContainer>
          <ArticlesSection articles={insightsArticles.data} />
        </StyledContainer>
      </ArticlesContainer> */}
    </HomeContainer>
  )
}

export default Home
