import React from "react"
import * as S from "./styles/BreadCrumb.styles"
import { ChevronRightWhite } from "../../assets/icons"
import { useLocation } from "react-router"

const BreadCrumb = () => {
  const location = useLocation()

  const text = location?.pathname.split("/")[1]

  const scrollIntoView = id => {
    arrayWithIDS.forEach(ID => {
      if (id === ID.location) {
        const x = document.getElementById(ID.idRef)
        x.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        })
      } else {
        window.scrollTo(0, 800)
      }
    })
  }

  return (
    <S.Wrapper>
      <S.Container onClick={() => scrollIntoView(text)}>
        <S.ChevronContainer>
          <ChevronRightWhite />
        </S.ChevronContainer>
        <S.TextContainer>
          <div>{text || "Home"}</div>
        </S.TextContainer>
      </S.Container>
    </S.Wrapper>
  )
}

export default BreadCrumb

const arrayWithIDS = [
  { id: 1, location: "", idRef: "homeIndustrySection" },
  { id: 2, location: "expertise", idRef: "expertiseIndustrySection" },
  { id: 3, location: "industry", idRef: "industryExpertiseId" },
  { id: 4, location: "leadership", idRef: "leadershipLeadersId" },
]
