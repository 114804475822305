import Axios from "axios"

const customApi = process.env.REACT_APP_CUSTOM_API_URL

export const getIdByAlias = async alias => {
  try {
    const link = `${customApi}/detect-entity-path-alias?alias=${alias}`

    const res = await Axios.get(link)

    // return Article uuid
    return res.data.uuid
  } catch (e) {
    console.log("This is e", e)
  }
}
