import Axios from "axios"
import { cleanDateFilter } from "./helper"

const customApi = process.env.REACT_APP_CUSTOM_API_URL
const jsonApi = process.env.REACT_APP_JSON_API_URL

export const getInsightFilters = async () => {
  try {
    const hlsLink = `${customApi}/hls`
    const categoriesLink = `${jsonApi}/taxonomy_term/insights?sort[sort-name][path]=name`

    let filters = {}

    const res = await Axios.get(hlsLink)

    filters.expertise = Object.values(res.data.expertise_parent_children).map(
      filterName => {
        return {
          id: filterName.id,
          uuid: filterName.uuid,
          name: filterName.name,
        }
      }
    )

    filters.industries = Object.values(res.data.healthcare_industries).map(
      filter => {
        return { id: filter.id, uuid: filter.uuid, name: filter.name }
      }
    )

    filters.region = res.data.insights_regions

    filters.date = cleanDateFilter(res.data.date_range)

    filters.content_type = [{id: 'article', name: 'Article'}, {id: 'podcast', name: 'Podcast'}, {id: 'video', name: 'Video'}]

    const res_categories = await Axios.get(categoriesLink)

    filters.categories = Object.values(res_categories.data.data).map(
      filter => {
        return {
          id: filter.attributes.drupal_internal__tid,
          name: filter.attributes.name,
          uuid: filter.id
        }
      }
    )

    filters.categories = [{
      name: "all insights",
      id: [1776, 1771, 3971],
    }, ...filters.categories]

    return filters
  } catch (e) {
    console.log(e)
    return null
  }
}
