import React, { useState, useRef, useEffect } from "react"
import { XIcon } from "../../assets/icons"

import { ButtonWithAnimation } from "../buttons"
import {
  Card,
  Header,
  Info,
  ContainerMobile,
  ContactInfo,
  Dropdown,
  PersonalInfo,
  XContainer,
  CardBody,
  ImageContainer,
  ImageTriangleContainer,
  Content,
  DescriptionDesktop,
  TopArrowImage,
  DetailCard,
} from "./styles/DetailedProfessionalsCard.styles"
import DOMPurify from "dompurify"

import { TriangleSVG } from "../../assets/icons"
const DetailedProfessionalsCard = ({
  content,
  handleDisplay,
  index,
  setPadding,
  showDetails,
}) => {
  const [display, setDisplay] = useState("none")
  const [borderBottom, setBorderBottom] = useState(true)
  const [smallLeaderDescription, setSmallLeaderDescription] = useState(true)
  const detailedCard = useRef(null)

  useEffect(() => {
    if (showDetails) {
      setPadding(detailedCard.current.clientHeight)
    }
  })

  const handleClick = () => {
    if (display === "none") {
      setDisplay("initial")
    } else {
      setDisplay("none")
    }

    setBorderBottom(!borderBottom)
  }

  return (
    <>
      <TopArrowImage>
        <TriangleSVG />
      </TopArrowImage>

      <DetailCard ref={detailedCard} display={display}>
        <div className="card-col-data">
          <div className="employee-photo">
            <img src={content?.image} alt="professional"></img>
          </div>

          <div className="employee-data">
            <div className="employee-title">
              <h4>{content?.name}</h4>
              <h6>{content?.position}</h6>
            </div>

            <div className="employee-description">
              <p>{content?.smallDescription}</p>
            </div>

            <div id="button-leaders">
              <ButtonWithAnimation
                text={"read more"}
                width={"100%"}
                onClick={() => {
                  window.open(content.linkOurPeople.replace(/\u200B/g, ''), "_blank");
                }}
              />
            </div>
          </div>

          <div className="employee-contact">
            {content?.country.name && (
              <PersonalInfo
                icon="location"
                title="location"
                content={content?.country.name}
                onClick={() => {
                  window.open(content?.linkLocation + "#gl_locations", "_blank")
                }}
              />
            )}
            {content?.email && (
              <PersonalInfo
                icon="mail"
                title="email"
                content={content?.email}
                onClick={() => {}}
              />
            )}
            {content?.phone && (
              <PersonalInfo
                icon="phone"
                title="telephone"
                content={content?.phone}
                onClick={() => {}}
              />
            )}
            {content?.twitter.title && (
              <PersonalInfo
                icon="twitter"
                title="twitter"
                content={content?.twitter.title}
                onClick={() => {
                  window.open(content?.twitter.uri, "_blank")
                }}
              />
            )}
            {content?.linkedin.title && (
              <PersonalInfo
                icon="linkedin"
                title="connect on"
                content={content?.linkedin.title}
                onClick={() => {
                  window.open(content?.linkedin.uri, "_blank")
                  console.log(content)
                }}
              />
            )}
          </div>
        </div>

        <div className="card-col-close-icon">
          <div className="close-icon" onClick={() => handleDisplay(index)}>
            <XIcon className={"x-icon"} />
          </div>
        </div>
      </DetailCard>
    </>
  )
}

export default DetailedProfessionalsCard