import React from "react"
import { ArticlePreviewCard } from "../cards"
import UnalignedItemsContainer from "../layout/UnalignedItemsContainer"
import styled from "styled-components"

const ArticleCotnainer = styled.div`
  padding: 50px 10px 0;
`

const ArticleSection = ({ articles }) => {
  return (
    <ArticleCotnainer>
      <UnalignedItemsContainer columnsNumber={3}>
        {articles?.map((article, index) => {
          let cardInfo = {
            id: article?.uuid,
            category: article?.categoryName,
            title: article?.title,
            date: article.date,
            alias: article.alias,
            teaserText: article?.text_teaser,
            uuid: article?.uuid,
          }
          return <ArticlePreviewCard key={index} {...cardInfo} />
        })}
      </UnalignedItemsContainer>
    </ArticleCotnainer>
  )
}

export default ArticleSection
