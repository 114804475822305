import React, { useState, useEffect, memo } from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { MediaList, MediaPreview } from "../cards"

const StyledCol1 = styled(Col)`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 992px) {
    justify-content: start;
  }
`

const StyledCol2 = styled(Col)`
  display: flex;
  justify-content: center;

  @media screen and (min-width: 992px) {
    justify-content: end;
  }
`

const MediaContainer = styled.div`
  flex-basis: 100%;
  max-width: 500px;
`

const FeaturedMediaSection = memo(({ categories }) => {
  const [activeCategory, setActiveCategory] = useState(undefined)
  const [currentItem, setCurrentItem] = useState(undefined)
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    if (categories.length) {
      const firstCategory = categories[0]
      setActiveCategory(firstCategory.name)
      setCurrentItem(firstCategory.items[0])
    }
  }, [categories])

  const handleClick = (index) => {
    setActiveItemIndex(index);
    if (window.innerWidth <= 768) {
      const box = document.getElementById("insightBox");
      box.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  };

  const handleCategoryClick = category => {
    setActiveCategory(category)
    const categoryObject = categories.find(c => c.name === category)
    if (categoryObject) {
      setCurrentItem(categoryObject.items[0])
    }
  }

  return (
    <Container style={{ paddingTop: "30px", paddingBottom: "50px" }}>
      <Row xs={1} lg={2}>
        <StyledCol1>
          <MediaContainer>
            <MediaList
              categories={categories}
              activeCategory={activeCategory}
              activeItem={currentItem?.id}
              handleCategoryClick={handleCategoryClick}
              handleItemClick={setCurrentItem}
              handleClick={handleClick}
            />
          </MediaContainer>
        </StyledCol1>
        <StyledCol2>
          <MediaContainer>
            {currentItem && (
              <div id="insightBox">
                <MediaPreview
                  name={currentItem?.title}
                  uuid={currentItem?.uuid}
                  activeCategory={activeCategory}
                  buttonColor={"var(--yellowCategory)"}
                  backgroundUrl={currentItem?.backgroundUrl}
                  textTeaser={currentItem?.textTeaser}
                  date={currentItem?.date}
                  videoUrl={currentItem?.videoUrl}
                  alias={currentItem?.alias}
                  canonicalUrl={currentItem?.canonicalUrl}
                />
              </div>
            )}
          </MediaContainer>
        </StyledCol2>
      </Row>
    </Container>
  )
})

export default FeaturedMediaSection
