import React from "react"
import styled from "styled-components/macro"
import { ButtonWithAnimation } from "../buttons"

const Card = styled.div`
  background-color: var(--graySections);
  width: auto;
  height: auto;
  margin: 20px 0;
  padding: 20px;
`

const FindExpertCard = () => {
  return (
    <Card>
      <ButtonWithAnimation
        text={"FIND AN EXPERT"}
        href={"/leadership"}
        target="_blank"
      />
    </Card>
  )
}

export default FindExpertCard
