import { CHANGE_ARTICLE_LANGUAGE } from "../actions/articleLanguage"

const initialState = {
  language: 'en',
}

const articleLanguageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_ARTICLE_LANGUAGE:
      return changeLanguage(state, payload)

    default:
      return state
  }
}

const changeLanguage = (state, payload) => {
  return {
    ...state,
    language: payload,
  }
}

export default articleLanguageReducer
