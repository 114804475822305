import { combineReducers } from "redux"
import leadersReducer from "./reducers/leaders"
import filtersReducer from "./reducers/filters"
import hlsHero from "./reducers/hls/hlsHome"
import articleLanguageReducer from './reducers/articleLanguage'

export default combineReducers({
  leaders: leadersReducer,
  hlsHero,
  filters: filtersReducer,
  article: articleLanguageReducer,
})
