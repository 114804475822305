import React from "react"
import styled from "styled-components/macro"
import { size } from "../../utils/breakpoints"

const Container = styled.div`
  padding: 50px 10px;
  & > div {
    display: flex;
    flex-direction: column;

    & > :last-child {
      border-bottom: none;
    }
  }

  @media ${size.md} {
    display: grid;
    grid-template-columns: repeat(${props => (props.columnsNumber >= 5) ? 2 : props.columnsNumber}, 1fr);
    gap: 1rem;
  }

  @media ${size.lg} {
    grid-template-columns: repeat(${props => (props.columnsNumber >= 5 )? 3 : props.columnsNumber}, 1fr);
    gap: ${props => props.gap}rem;
  }

  @media ${size.xl} {
    grid-template-columns: repeat(${props => props.columnsNumber }, 1fr);
    gap: ${props => props.gap}rem;
  }
`

const UnalignedItemsContainer = ({ children, columnsNumber, gap }) => {
  if (!columnsNumber) {
    columnsNumber = 3
  }

  if (!gap) {
    gap = 4
  }

  // Create empty arrays equal to columnsNumber
  let arrays = []
  for (let i = 0; i < columnsNumber; i++) {
    arrays?.push([])
  }

  // Fancy funtion to split the the array(children) into arrays of length columnsNumber
  if (children) {
    arrays = arrays?.map((arr, parentIndex) => {
      while (children[parentIndex]) {
        arr.push(children[parentIndex])
        parentIndex = parentIndex + columnsNumber
      }
      return arr
    })
  }

  return (
    <Container className="px-0" columnsNumber={columnsNumber} gap={gap}>
      {arrays.map((arr, index) => {
        return (
          <div key={index}>
            {arr?.map(child => {
              return child
            })}
          </div>
        )
      })}
    </Container>
  )
}

export default UnalignedItemsContainer
