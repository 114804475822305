export const dateFromSecondsToShortLocale = date => {
  return new Date(+date * 1000).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  })
}

export const dateToShortLocale = date => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}
