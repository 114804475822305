import React, { useEffect } from "react"
import styled from "styled-components/macro"
import TagManager from "react-gtm-module"

// hooks
import { useLocation } from "react-router"
import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "react-redux"

import { useDocumentTitle } from "../../hook"

// Costum Components
import {
  HeroSection,
  ExpertiseServiceCard,
  ProfessionalCard,
} from "../../components/cards"
import UnalignedItemsContainer from "../../components/layout/UnalignedItemsContainer"
import { BorderBottom } from "../../components/cards/styles/ArticlePreviewCard.styles"
import {
  ContainerContent,
  IndustryContainer,
  StyledContainer,
} from "../../components/layout/Rows&Collumns/Rows&Collumns.style"
import ArticlesSection from "../../components/section/ArticlesSection"

import { ContactSubscribeCard } from "../../components/cards"
import SocialsCardSmall from "../../components/cards/SocialsCardSmall"

//Actions
import {
  fetchExpertise,
  fetchHlsExpertises,
} from "../../store/actions/hls/hlsHome"

const ExpertisePageContainer = styled.div`
  .container-left {
    padding: 50px 10px;
  }
  .leftSection {
    .industrySection {
      h3 {
        color: #00244a;
      }
      .expertiseIndustry {
        color: var(--hover-blue);
        padding-right: 1rem;
        .expertiseIndustryText {
          cursor: pointer;
        }
      }
    }
  }
  .heroContainer {
    h1 {
      font-size: 80px;
      line-height: 80px;
      letter-spacing: 1px;
    }
  }

  span {
    color: var(--hover-blue);
    padding-right: 1rem;
  }
`

const ExpertisePage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { state } = useLocation()
  const navigate = useNavigate()

  const expertise = useSelector(state => state?.hlsHero?.expertise)
  const expertises = useSelector(state => state.hlsHero?.expertises)

  useEffect(() => {
    dispatch(fetchExpertise(state))
  }, [state, dispatch])

  //access directly from link
  if (!expertise.name) {
    dispatch(fetchHlsExpertises())
    const URLLocation = location?.pathname?.split("/")[2]
    const expertiseName = URLLocation.split("-").join(" ").toUpperCase()
    let x = expertises?.filter(x => x.name.toUpperCase() === expertiseName)

    dispatch(fetchExpertise(x[0]?.id))
  }

  useDocumentTitle(
    `Expertise |   ${
      expertise?.name ? expertise.name : " "
    }    | Alvarez & Marsal`
  )

  // Google tag manager
  const tagManagerArgs = {
    dataLayer: {
      'url': window.location.href,
      'pageTitle': `Expertise |   ${ expertise?.name ? expertise.name : " " }`,
      'reportingDivision': 'Healthcare',
      'reportingServiceLine': 'Healthcare',
      'reportingRegion': [
        'Europe',
        'Middle East'
      ],
      'reportingCountry': [
        'Benelux',
        'Portugal',
        'Cayman Islands',
        'Czech Republic',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Ireland',
        'Italy',
        'Norway',
        'Poland',
        'Spain',
        'Sweden',
        'Switzerland',
        'Ukraine',
        'United Kingdom',
        'Saudi Arabia',
        'United Arab Emirates',
        'Uzbekistan'
      ]
    }
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <ExpertisePageContainer>
      <HeroSection
        height={360}
        pageCategory="Expertise"
        title={expertise?.name}
        backgroundUrl={expertise?.backgroundUrl}
      />
      <ContainerContent>
        <IndustryContainer>
          <div className="d-lg-flex">
            <div className="col-12 col-lg-8 container-left">
              <div
                dangerouslySetInnerHTML={{
                  __html: expertise?.description ?? expertise?.teaser_text,
                }}
              />
              <UnalignedItemsContainer
                className="px-0"
                columnsNumber={expertise?.articles?.legnth}
              >
                {expertise?.articles?.map(article => (
                  <ExpertiseServiceCard
                    title={article?.title}
                    conntent={article?.text_teaser}
                  />
                ))}
              </UnalignedItemsContainer>
              <BorderBottom color={"var(--hover-blue)"} className="my-3" />
              <div
                className="industrySection mt-4"
                id="expertiseIndustrySection"
              >
                <h3 className="fw-bold">Industries:</h3>
                {expertise?.industries?.map(expertiseIndustry => (
                  <span
                    key={expertiseIndustry?.id}
                    className="expertiseIndustry"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/industry/${expertiseIndustry?.name
                          .toLowerCase()
                          .split(" ")
                          .join("-")}`,
                        {
                          state: {
                            industryId: expertiseIndustry.id,
                            industryUUID: expertiseIndustry.uuid,
                          },
                        }
                      )
                    }
                  >
                    {expertiseIndustry?.name}
                  </span>
                ))}
              </div>
            </div>

            <div className="py-5 expertsContainer col-12 col-lg-4  ps-lg-3 ps-xxl-5 container-right">
              {expertise?.experts?.map(expert => {
                let cardContent = {
                  imageSrc: expert?.image,
                  name: expert?.title,
                  position: expert?.profession_title,
                  country: expert?.global_location,
                  alias: expert?.url,
                  buttonText: "connect",
                }
                return <ProfessionalCard {...cardContent} />
              })}
              <div className="w-100">
                <SocialsCardSmall />
                <ContactSubscribeCard />
              </div>
            </div>
          </div>
        </IndustryContainer>
      </ContainerContent>
      <ArticlesContainer>
        <StyledContainer>
          <ArticlesSection articles={expertise?.articles} />
        </StyledContainer>
      </ArticlesContainer>
    </ExpertisePageContainer>
  )
}

export default ExpertisePage

const ArticlesContainer = styled.div`
  width: 100%;
  background-color: var(--graySections);
  color: var(--darkBlueHome);
`
